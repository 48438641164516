<template>
  <div class="hero">
    <div class="container h-100 d-flex justify-center align-items-center">
      <div class="hero-title mt-16 text-center">
        <h1><span>مُعاون</span> يُعين ويعاون</h1>
        <p><slot></slot></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.hero {
  height: 280px;
}
.hero .hero-title span {
  background: linear-gradient(88deg, #3f55a2 77.16%, #947ab6 100.14%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
