<template>
  <div class="freelancer-page">
    <UserHero
      :user_name="user_name"
      :user_specialization="user_specialization"
      :user_rate="user_rate"
      :category="category"
    />
    <!-- =============== -->
    <div class="container mb-5 pb-5">
      <div class="row g-4">
        <bio-info :ratingFeedback="ratingFeedback" :rating="rating">
          <template #bio>نبذة عن المعاون</template>
          <template #user-services>خدمات المعاون</template>
          <template #reviews>تقييمات العملاء</template>
          <template #num-of-reviews>تعليق من العملاء</template>
        </bio-info>
        <!-- ================ -->
        <user-activity-summary>
          <template #services-provided>الخدمات المقدمة</template>
          <template #services-completed>الخدمات المكتملة</template>
          <template #services-current>المشاريع الحالية</template>
        </user-activity-summary>
      </div>
    </div>
  </div>
</template>

<script>
import UserActivitySummary from "@/components/freelancer&client/UserActivitySummary.vue";
import UserHero from "@/components/freelancer&client/UserHero.vue";
import BioInfo from "@/components/freelancer&client/BioInfo.vue";
export default {
  components: {
    UserHero,
    UserActivitySummary,
    BioInfo,
  },
  data() {
    return {
      ratingFeedback: 0,
      rating: 5,
      user_name: "عمر القصيدي",
      user_specialization: "مهندس معماري",
      user_rate: 4.7,
      category: "المعاونون",
    };
  },
};
</script>

<style scoped>
.container {
  margin-top: -40px;
}
</style>
