<template>
  <div class="top-freelancers-comp pb-5 pb-lg-0 mb-lg-0 pt-lg-5">
    <div class="container">
      <div class="d-flex align-items-center">
        <svg
          v-if="!isSmallScreen"
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="22"
          viewBox="0 0 23 22"
          fill="none"
        >
          <circle cx="20" cy="3" r="3" fill="#5264AE" />
          <circle cx="20" cy="11" r="3" fill="#5264AE" />
          <circle cx="20" cy="19" r="3" fill="#5264AE" />
          <circle cx="12" cy="15" r="3" fill="#5264AE" />
          <circle cx="12" cy="7" r="3" fill="#5264AE" />
          <circle cx="3" cy="11" r="3" fill="#5264AE" />
        </svg>
        <h2 v-if="isSmallScreen">معاونين مميزين</h2>
        <h3 class="ms-2" v-else>
          خدمات احترافية في شتى المجالات، اطلب معاون يعاون
        </h3>
      </div>
      <v-slide-group
        v-if="isSmallScreen"
        dir="rtl"
        v-model="model"
        class="mt-3"
        selected-class="bg-success"
        show-arrows
      >
        <v-slide-group-item
          v-for="freelancer in freelancers"
          :key="freelancer.id"
        >
          <div class="freelancer-card mx-3">
            <div class="freelancer-image">
              <img
                :src="freelancer.image"
                class="w-100"
                :alt="freelancer.name"
              />
            </div>
            <div>
              <p class="nowrap mb-0">
                {{ freelancer.name }} / {{ freelancer.profession }}
              </p>
            </div>
            <v-rating
              class="rating"
              size="25"
              :model-value="freelancer.rating"
              readonly
              activeColor="orange"
              color="white"
            ></v-rating>
          </div>
        </v-slide-group-item>
      </v-slide-group>
      <div v-else class="row g-4 row-cols-5 mt-2">
        <div class="col" v-for="freelancer in freelancers" :key="freelancer.id">
          <div class="freelancer-card">
            <div class="freelancer-image">
              <img
                :src="freelancer.image"
                class="w-100"
                :alt="freelancer.name"
              />
            </div>
            <div>
              <p class="nowrap mb-0">
                {{ freelancer.name }} / {{ freelancer.profession }}
              </p>
            </div>
            <v-rating
              class="rating"
              size="25"
              :model-value="freelancer.rating"
              readonly
              activeColor="orange"
              color="white"
            ></v-rating>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      model: null,
      rating: 3,
      freelancers: [
        {
          id: 1,
          name: "احمد فودة",
          profession: "مصمم",
          rating: 4,
          image:
            "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
        },
        {
          id: 2,
          name: "سارة محمد",
          profession: "مبرمج",
          rating: 5,
          image:
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
        },
        {
          id: 3,
          name: "محمود علي",
          profession: "كاتب محتوى",
          rating: 3,
          image:
            "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
        },
        {
          id: 4,
          name: "فاطمة حسن",
          profession: "مترجم",
          rating: 4,
          image:
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
        },
        {
          id: 5,
          name: "خالد عمر",
          profession: "مسوق رقمي",
          rating: 5,
          image:
            "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
        },
      ],
    };
  },
  computed: {
    isSmallScreen() {
      return this.windowWidth < 992;
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
<style scoped>
.freelancer-card {
  border-radius: 8px;
  background: radial-gradient(
    52.49% 52.49% at 50% 30.39%,
    rgba(32, 43, 81, 0.6) 0%,
    rgba(32, 43, 80, 0.18) 100%
  );
  backdrop-filter: blur(22px);
  display: flex;
  padding: 10px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
}
.freelancer-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}
.rating {
  font-size: 12px !important;
}
::v-deep .v-slide-group__next,
::v-deep .v-slide-group__prev {
  display: none !important;
}
</style>
