<template>
  <div class="col-lg-9 blog-content">
    <div
      v-for="post in posts"
      :key="post.id"
      class="post mb-4 row d-flex align-items-center"
    >
      <div class="col-5">
        <div class="post-cover">
          <img :src="post.image" class="w-100" :alt="post.title" />
        </div>
      </div>
      <div class="col-7 post-details">
        <div class="post-body">
          <h5 class="post-title px-2">{{ post.title }}</h5>
          <p>{{ post.content }}</p>
          <div class="d-flex justify-content-end">
            <div class="redPostBtn">
              <router-link class="text-decoration-none text-light p-3"
                >تصفح المقال
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="11"
                    viewBox="0 0 10 12"
                    fill="none"
                  >
                    <path
                      d="M9.16232 1.41L7.55676 0L0.724609 6L7.55676 12L9.16232 10.59L3.94711 6L9.16232 1.41Z"
                      fill="#5264AE"
                    />
                  </svg> </span
              ></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <LatestPosts />
</template>

<script>
import LatestPosts from "./LatestPosts.vue";

export default {
  components: {
    LatestPosts,
  },
  data() {
    return {
      posts: [
        {
          id: 1,
          title: "آلية عمل منصة معاون",
          content:
            "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما-",
          image: require("../../assets/imgs/blog/blog-1.png"),
        },
        {
          id: 2,
          title: "كيفية الاستفادة من خدمات معاون",
          content:
            "لوريم إيبسوم هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص.",
          image: require("../../assets/imgs/blog/blog-2.png"),
        },
        {
          id: 3,
          title: "أهمية التعلم المستمر في العمل",
          content:
            "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما-",
          image: require("../../assets/imgs/blog/blog-3.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.blog .blog-content .post-cover {
  border-radius: 24px 4px 24px 24px;
  backdrop-filter: blur(22px);
  overflow: hidden;
}
.blog .blog-content .post-title {
  border-right: 3px solid #5264ae;
}
.blog .blog-content .post-details {
  margin-right: -50px;
}
.blog .blog-content .post-details .post-body {
  border-radius: 20px 4px 20px 20px;
  background: #171b21;
  backdrop-filter: blur(22px);
  padding: 15px;
}
.redPostBtn {
  font-size: 14px;
}
</style>
