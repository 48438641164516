<template>
  <div class="hero">
    <div class="container h-100 d-flex justify-center align-items-center">
      <div class="row w-100 align-items-center">
        <div>
          <v-breadcrumbs :items="items" divider="" class="px-0 m-0 breadcrumbs">
            <template v-slot:divider>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="20"
                viewBox="0 0 14 20"
                fill="none"
              >
                <path
                  d="M14 2.35L11.336 0L0 10L11.336 20L14 17.65L5.34683 10L14 2.35Z"
                  fill="#5264AE"
                />
              </svg>
            </template>
          </v-breadcrumbs>
        </div>
        <p>{{ details }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    items: Object,
    details: String,
  },
  data: () => ({}),
};
</script>
<style scoped>
.hero {
  height: 360px;
  background-image: url(../../assets/imgs/home/hero-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767px) {
  .hero {
    height: 300px;
  }
}
.hero .row {
  /* background-color: red; */
  margin-top: 64px;
}
.hero .breadcrumbs {
  font-size: 40px;
}
@media (max-width: 767px) {
  .hero .breadcrumbs {
    font-size: 30px;
  }
}
::v-deep .v-breadcrumbs-item--link:hover {
  text-decoration: none !important;
}
</style>
