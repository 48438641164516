<template>
  <div class="blog">
    <!-- hero section -->
    <div class="hero">
      <div class="container h-100 d-flex justify-center align-items-center">
        <div class="row w-100 align-items-center">
          <h2 class="mb-3">جسرك الآمن للوصول إلى خدمات المستقلين</h2>
          <p>
            حلول ذكية، مهارات متعددة، ومستقلين محترفين من أنحاء العالم؛ لنسهل
            عليك البدء في <br class="d-none d-lg-block" />
            مشروعك بإمكانيات عالية وفق ميزانيتك
          </p>
        </div>
      </div>
    </div>
    <!-- hero section -->
    <div class="container">
      <!-- main post section -->
      <div
        class="row d-none d-lg-flex align-items-center position-relative pt-5"
      >
        <div class="col-md-8">
          <div class="main-post-cover">
            <img src="../assets/imgs/blog/main-bg.png" class="w-100" alt="" />
          </div>
        </div>
        <div class="col-md-6 position-absolute left-0">
          <div class="main-post p-3">
            <h3 class="main-post-title px-2">
              معاون يطلــق خدمة التعليم الالكتروني
            </h3>
            <p>
              هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما
              سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات
              في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها
              تعطي
            </p>
            <div class="d-flex justify-content-between align-items-center">
              <PostDetails
                :date="postDetails.date"
                :readTime="postDetails.readTime"
              />
              <div class="redPostBtn">
                <router-link class="text-decoration-none text-light p-3"
                  >تصفح المقال
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="12"
                      viewBox="0 0 10 12"
                      fill="none"
                    >
                      <path
                        d="M9.16232 1.41L7.55676 0L0.724609 6L7.55676 12L9.16232 10.59L3.94711 6L9.16232 1.41Z"
                        fill="#5264AE"
                      />
                    </svg> </span
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- blog content -->
      <div class="row pt-5 py-lg-5">
        <!-- blog content large screen -->
        <BlogContentLg v-if="isLargeScreen" />
        <!-- blog content small screen -->
        <BlogContentSm v-else />
      </div>
      <!-- contact section -->
      <div class="py-5">
        <div class="row contact-section position-relative mx-0 bg-green">
          <div
            class="blog-contact-overlay d-flex justify-center align-items-center"
          >
            <div class="text-center">
              <h3>
                شارك إبداعك مع العالم وزد فرص اختيارك من قبل أصحاب المشاريع
              </h3>
              <p>
                منصة معاون تقدم دعوة للكتاب المبدعين لمشاركة فنهم عبر مدونة
                معاون،
                <br class="d-none d-lg-block" />
                لتمكينهم من إظهار مهاراتهم وقدراتهم الكتابية.
              </p>
              <v-btn class="btn-custom dark-btn mt-2 mt-lg-3">
                <div>تواصل معنا لنبدأ بالنشر</div>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <!-- contact us section -->
    </div>
  </div>
</template>

<script>
import BlogContentLg from "../components/blog/BlogContentLg.vue";
import BlogContentSm from "../components/blog/BlogContentSm.vue";
import PostDetails from "../components/blog/PostDetails.vue";
export default {
  components: {
    BlogContentLg,
    BlogContentSm,
    PostDetails,
  },
  data() {
    return {
      postDetails: {
        date: "15 . 7 . 2023",
        readTime: "7 د",
      },
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    isLargeScreen() {
      return this.windowWidth >= 992;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.hero {
  height: 360px;
  background-image: url(../assets/imgs/home/hero-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767px) {
  .hero {
    height: 300px;
  }
  .blog .contact-section {
    height: 250px;
  }
}
.hero .row {
  margin-top: 64px;
}
@media (max-width: 767px) {
  .hero h2 {
    font-size: 23px;
  }
}
.blog .main-post-cover {
  border-radius: 20px 4px 20px 20px;
  backdrop-filter: blur(22px);
  overflow: hidden;
}
.blog .main-post {
  border-radius: 12px;
  border: 1px solid #5264ae;
  background: #171b21;
  backdrop-filter: blur(22px);
}
.blog .main-post-title {
  border-right: 3px solid #5264ae;
}
.blog .blog-contact-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 20px 4px 20px 20px;
  border: 1px solid #5264ae;
  background: linear-gradient(
    81deg,
    rgba(37, 46, 60, 0.9) -10.46%,
    rgba(12, 22, 37, 0.9) 45.8%
  );
  overflow: hidden;
}
.blog .contact-section {
  height: 300px;
  background-image: url(../assets/imgs/blog/contact-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px 4px 20px 20px;
}
@media (max-width: 767px) {
  .blog .contact-section {
    height: 250px;
  }
  .contact-section h3 {
    font-size: 20px;
    line-height: 28px;
  }
}
</style>
