<template>
  <div class="client-security dash-main-comp">
    <div
      class="box mb-3 d-none d-lg-flex justify-space-between align-items-center"
    >
      <h5 class="mb-0">الأمان والخصوصية</h5>
      <div class="d-flex align-items-center gap-2">
        <span>حالة الحساب :</span>
        <v-btn class="btn-custom dark-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="9"
            viewBox="0 0 8 9"
            fill="none"
          >
            <circle cx="4" cy="4.5" r="4" fill="#52AE77" />
          </svg>
          <span>متاح للعمل</span>
        </v-btn>
      </div>
    </div>
    <div class="box mb-3">
      <h5 class="mb-3 d-none d-lg-block">إعادة تعيين كلمة السر</h5>
      <div
        class="d-flex d-lg-none justify-space-between align-items-center mb-3"
      >
        <h5 class="mb-0">الأمان والخصوصية</h5>
        <div>
          <v-btn class="btn-custom dark-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="9"
              viewBox="0 0 8 9"
              fill="none"
            >
              <circle cx="4" cy="4.5" r="4" fill="#52AE77" />
            </svg>
            <span>متاح للعمل</span>
          </v-btn>
        </div>
      </div>
      <v-form>
        <div class="row">
          <div class="col-lg-4 col-sm-6">
            <div>
              <label>كلمة المرور</label>
              <v-text-field
                dir="rtl"
                :prepend-inner-icon="
                  visibleFields.current ? 'mdi-eye-off' : 'mdi-eye'
                "
                :type="visibleFields.current ? 'text' : 'password'"
                density="compact"
                @click:prepend-inner="toggleVisibility('current')"
              ></v-text-field>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div>
              <label>كلمة المرور الجديدة</label>
              <v-text-field
                dir="rtl"
                :prepend-inner-icon="
                  visibleFields.new ? 'mdi-eye-off' : 'mdi-eye'
                "
                :type="visibleFields.new ? 'text' : 'password'"
                density="compact"
                @click:prepend-inner="toggleVisibility('new')"
              ></v-text-field>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div>
              <label>إعادة تأكيد كلمة المرور الجديدة</label>
              <v-text-field
                dir="rtl"
                :prepend-inner-icon="
                  visibleFields.confirm ? 'mdi-eye-off' : 'mdi-eye'
                "
                :type="visibleFields.confirm ? 'text' : 'password'"
                density="compact"
                @click:prepend-inner="toggleVisibility('confirm')"
              ></v-text-field>
            </div>
          </div>
          <div class="col-12">
            <v-btn class="btn-custom dark-btn w-100">
              <span>حفظ</span>
            </v-btn>
          </div>
        </div>
      </v-form>
    </div>
    <div class="box mb-3">
      <div class="d-flex justify-space-between align-items-center mb-3">
        <h5 class="mb-0">بطاقات البنك</h5>
        <div>
          <v-btn class="btn-custom dark-btn">
            <div class="d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 13"
                fill="none"
                class="me-2"
              >
                <path
                  d="M6.00022 11.5002V1.49978M1 6.5H11.0004"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span class="d-none d-lg-block">اضافة بطاقة جديدة</span>
            </div>
          </v-btn>
        </div>
      </div>
      <div class="row g-3">
        <div class="col-sm-6 col-lg-4">
          <div>
            <label>البطاقة الرئيسية</label>
            <div class="bank-card">
              <div>
                <div class="d-flex align-items-center mb-1 gap-1">
                  <span>رقم البطاقة :</span>
                  <span class="sm txt-muted">XXXX XXXX XXXX 1501</span>
                </div>
                <div class="d-flex align-items-center">
                  <v-btn class="btn-custom dark-btn" density="compact">
                    <span>تعديل</span>
                  </v-btn>
                  <v-btn
                    class="text-red bg-transparent shadow-none"
                    density="compact"
                  >
                    <span>حذف</span>
                  </v-btn>
                </div>
              </div>
              <div class="d-flex flex-column align-items-center">
                <img
                  src="../../assets/imgs/dashboard/visa.svg"
                  alt="bank-card"
                />

                <div class="sm txt-muted mt-1">09 / 2030</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div>
            <label>البطاقة الثانوية</label>
            <div class="bank-card">
              <div>
                <div class="d-flex align-items-center mb-1 gap-1">
                  <span>رقم البطاقة :</span>
                  <span class="sm txt-muted">XXXX XXXX XXXX 1501</span>
                </div>
                <div class="d-flex align-items-center">
                  <v-btn class="btn-custom dark-btn" density="compact">
                    <span>تعديل</span>
                  </v-btn>
                  <v-btn
                    class="text-red bg-transparent shadow-none"
                    density="compact"
                  >
                    <span>حذف</span>
                  </v-btn>
                </div>
              </div>
              <div class="d-flex flex-column align-items-center">
                <img
                  src="../../assets/imgs/dashboard/visa.svg"
                  alt="bank-card"
                />

                <div class="sm txt-muted mt-1">09 / 2030</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visibleFields: {
        current: false,
        new: false,
        confirm: false,
      },
    };
  },
  methods: {
    toggleVisibility(field) {
      this.visibleFields[field] = !this.visibleFields[field];
    },
  },
};
</script>
<style scoped>
.dash-main-comp {
  background-color: #10141b;
  padding: 30px 15px;
  height: 100%;
}
/* ======= */
.box {
  background-color: rgba(24, 25, 45, 1);
  border-radius: 12px;
  padding: 15px 10px;
}
.bank-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 8px;
  background: #10141b;
}
.sm {
  font-size: 12px;
}
.txt-muted {
  color: #808080 !important;
}
</style>
