<template>
  <div class="terms-and-privacy-component">
    <div class="hero">
      <div class="container h-100 hero-title d-flex align-items-center">
        <div>
          <h4 class="fw-bold">{{ heading }}</h4>
          <p>
            {{ description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TermsAndPrivacy",
  props: {
    heading: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.hero {
  height: 360px;
  background-image: url(../../assets/imgs/home/hero-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 768px) {
  .hero {
    height: 300px;
  }
}
.hero-title div {
  margin-top: 64px;
}
</style>
