<template>
  <div class="d-flex align-items-center small">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M14.2936 1.6H12.6936V0.8C12.6936 0.587827 12.6093 0.384344 12.4593 0.234315C12.3093 0.0842854 12.1058 0 11.8936 0C11.6814 0 11.4779 0.0842854 11.3279 0.234315C11.1779 0.384344 11.0936 0.587827 11.0936 0.8V1.6H6.2936V0.8C6.2936 0.587827 6.20932 0.384344 6.05929 0.234315C5.90926 0.0842854 5.70578 0 5.4936 0C5.28143 0 5.07795 0.0842854 4.92792 0.234315C4.77789 0.384344 4.6936 0.587827 4.6936 0.8V1.6H3.0936C2.45708 1.6 1.84663 1.85286 1.39655 2.30294C0.94646 2.75303 0.693604 3.36348 0.693604 4V13.6C0.693604 14.2365 0.94646 14.847 1.39655 15.2971C1.84663 15.7471 2.45708 16 3.0936 16H14.2936C14.9301 16 15.5406 15.7471 15.9907 15.2971C16.4407 14.847 16.6936 14.2365 16.6936 13.6V4C16.6936 3.36348 16.4407 2.75303 15.9907 2.30294C15.5406 1.85286 14.9301 1.6 14.2936 1.6ZM15.0936 13.6C15.0936 13.8122 15.0093 14.0157 14.8593 14.1657C14.7093 14.3157 14.5058 14.4 14.2936 14.4H3.0936C2.88143 14.4 2.67795 14.3157 2.52792 14.1657C2.37789 14.0157 2.2936 13.8122 2.2936 13.6V8H15.0936V13.6ZM15.0936 6.4H2.2936V4C2.2936 3.78783 2.37789 3.58434 2.52792 3.43431C2.67795 3.28429 2.88143 3.2 3.0936 3.2H4.6936V4C4.6936 4.21217 4.77789 4.41566 4.92792 4.56569C5.07795 4.71571 5.28143 4.8 5.4936 4.8C5.70578 4.8 5.90926 4.71571 6.05929 4.56569C6.20932 4.41566 6.2936 4.21217 6.2936 4V3.2H11.0936V4C11.0936 4.21217 11.1779 4.41566 11.3279 4.56569C11.4779 4.71571 11.6814 4.8 11.8936 4.8C12.1058 4.8 12.3093 4.71571 12.4593 4.56569C12.6093 4.41566 12.6936 4.21217 12.6936 4V3.2H14.2936C14.5058 3.2 14.7093 3.28429 14.8593 3.43431C15.0093 3.58434 15.0936 3.78783 15.0936 4V6.4Z"
        fill="white"
      />
    </svg>
    <span class="px-1">{{ date }}</span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      viewBox="0 0 15 14"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.69678 0C3.83893 0 0.693604 3.14087 0.693604 6.99809C0.693604 10.8553 3.83956 14 7.69678 14C11.554 14 14.6936 10.8553 14.6936 6.99809C14.6936 3.14087 11.554 0 7.69678 0ZM7.69678 1.27238C8.44899 1.27112 9.19404 1.41837 9.88921 1.70569C10.5844 1.993 11.216 2.41473 11.7478 2.94668C12.2797 3.47862 12.7012 4.11033 12.9884 4.80557C13.2756 5.5008 13.4226 6.24589 13.4212 6.99809C13.4227 7.7505 13.2757 8.49581 12.9886 9.19129C12.7016 9.88678 12.28 10.5188 11.7482 11.051C11.2164 11.5833 10.5848 12.0054 9.8896 12.2931C9.19437 12.5808 8.44919 12.7285 7.69678 12.7276C6.94405 12.7285 6.19853 12.581 5.50291 12.2934C4.80728 12.0058 4.17521 11.5838 3.64289 11.0516C3.11057 10.5194 2.68845 9.88743 2.40071 9.19187C2.11296 8.49631 1.96523 7.75082 1.96598 6.99809C1.96598 3.82859 4.52728 1.27238 7.69678 1.27238ZM7.6866 2.53585C7.60251 2.53668 7.51942 2.55417 7.44213 2.5873C7.36484 2.62044 7.29489 2.66858 7.23632 2.72892C7.17775 2.78927 7.13173 2.86062 7.10091 2.93887C7.07009 3.01711 7.05509 3.10069 7.05678 3.18477V6.99809C7.05713 7.08181 7.074 7.16463 7.10642 7.24182C7.13885 7.319 7.18619 7.38902 7.24573 7.44788L9.79049 9.99391C9.84935 10.0545 9.91969 10.1027 9.99739 10.1358C10.0751 10.1689 10.1586 10.1862 10.2431 10.1867C10.3275 10.1872 10.4113 10.1709 10.4893 10.1387C10.5674 10.1065 10.6383 10.059 10.6979 9.99912C10.7574 9.93923 10.8044 9.86808 10.8362 9.78981C10.868 9.71155 10.8838 9.62775 10.8829 9.54329C10.8819 9.45883 10.8641 9.37541 10.8306 9.29789C10.7971 9.22038 10.7484 9.15031 10.6875 9.09179L8.33043 6.73471V3.18477C8.33214 3.09951 8.3167 3.01478 8.28501 2.93561C8.25333 2.85644 8.20605 2.78445 8.14599 2.72392C8.08593 2.66338 8.01431 2.61554 7.9354 2.58323C7.85648 2.55093 7.77187 2.53481 7.6866 2.53585Z"
        fill="white"
      />
    </svg>
    <span class="px-1">مدة القراءة : {{ readTime }}</span>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      required: true,
    },
    readTime: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.small {
  font-size: 10px;
}
@media (min-width: 800px) and (max-width: 1024px) {
  .small {
    /* font-size: 5px; */
  }
}
</style>
