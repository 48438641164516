<template>
  <div class="start-project-comp my-5 py-5">
    <div class="container">
      <div class="row start-project-section position-relative mx-0">
        <div
          class="start-project-overlay d-flex justify-center align-items-center"
        >
          <div class="text-center">
            <h3>{{ title }}</h3>
            <p v-html="desc"></p>
            <v-btn class="btn-custom dark-btn mt-md-2">
              <div>{{ btnTitle }}</div>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
    btnTitle: String,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.start-project-section {
  height: 300px;
  background-image: url("../../assets/imgs/home/start-project-bg-1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .start-project-section {
    height: 250px;
  }
  .start-project-section h3 {
    font-size: 20px;
  }
}
.start-project-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 12px;
  border: 1px solid #5264ae;
  background: linear-gradient(
    81deg,
    rgba(37, 46, 60, 0.9) -10.46%,
    rgba(12, 22, 37, 0.9) 45.8%
  );
}
</style>
