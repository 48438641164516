<template>
  <div class="container content my-16">
    <slot name="content"></slot>
    <TermsAndPrivacyLowerComp />
  </div>
</template>

<script>
import TermsAndPrivacyLowerComp from "./TermsAndPrivacyLowerComp.vue";

export default {
  components: { TermsAndPrivacyLowerComp },
};
</script>

<style scoped></style>
