<template>
  <div class="freelancer-home dash-main-comp">
    <h3 class="mb-4">الاحصائيات</h3>
    <div class="row g-4">
      <div class="col-lg-3 col-md-6">
        <div class="statistic-item py-5 px-3">
          <div
            class="stat-num d-flex justify-content-center align-items-center rounded-circle"
          >
            31
          </div>
          <h4 class="mt-2">المشاريع الحالية</h4>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="statistic-item py-5 px-3">
          <div
            class="stat-num d-flex justify-content-center align-items-center rounded-circle"
          >
            12
          </div>
          <h4 class="mt-2">المشاريع المنجزة</h4>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="statistic-item py-5 px-3">
          <div
            class="stat-num d-flex justify-content-center align-items-center rounded-circle"
          >
            25
          </div>
          <h4 class="mt-2">المشاريع الملغاة</h4>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="statistic-item py-5 px-3">
          <div
            class="stat-num d-flex justify-content-center align-items-center rounded-circle"
          >
            10
          </div>
          <h4 class="mt-2">التقييمات</h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.dash-main-comp {
  background-color: #10141b;
  padding: 30px 15px;
  height: 100%;
}
/* ====== */
.statistic-item {
  background-color: #18192d;
  border-radius: 12px;
}
.stat-num {
  width: 50px;
  height: 50px;
  background-color: #2f3761;
  font-size: 18px;
}
@media (min-width: 767px) and (max-width: 1280px) {
  .statistic-item h4 {
    font-size: 18px;
  }
}
</style>
