<template>
  <div class="col-lg-3">
    <div class="latest-posts">
      <h3 class="mb-3">اخر المقالات</h3>
      <div
        v-for="(post, index) in latestPosts"
        :key="index"
        class="latest-post mb-4"
      >
        <div class="post-cover">
          <img :src="post.image" class="w-100" :alt="post.title" />
        </div>
        <div class="latest-post-details">
          <h6 class="post-title px-2 mb-3">{{ post.title }}</h6>
          <div class="d-flex justify-content-between align-items-center">
            <PostDetails
              :date="postDetails.date"
              :readTime="postDetails.readTime"
            />
            <div class="redPostBtn">
              <router-link class="text-decoration-none text-light py-3"
                >تصفح المقال
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="10"
                    viewBox="0 0 10 12"
                    fill="none"
                  >
                    <path
                      d="M9.16232 1.41L7.55676 0L0.724609 6L7.55676 12L9.16232 10.59L3.94711 6L9.16232 1.41Z"
                      fill="#5264AE"
                    />
                  </svg> </span
              ></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostDetails from "./PostDetails.vue";
export default {
  components: {
    PostDetails,
  },
  data() {
    return {
      latestPosts: [
        {
          title: "كيفية تحسين أداء موقعك الإلكتروني",
          image: require("../../assets/imgs/blog/blog-1.png"),
          date: "15 . 7 . 2023",
          readTime: "7 د",
        },
        {
          title: "أفضل الممارسات لتصميم واجهة المستخدم",
          image: require("../../assets/imgs/blog/blog-3.png"),
          date: "22 . 6 . 2023",
          readTime: "5 د",
        },
        {
          title: "مقدمة في تطوير تطبيقات الهاتف المحمول",
          image: require("../../assets/imgs/blog/blog-2.png"),
          date: "10 . 6 . 2023",
          readTime: "8 د",
        },
      ],
      postDetails: {
        date: "15 . 7 . 2023",
        readTime: "7 د",
      },
    };
  },
};
</script>

<style scoped>
.latest-posts h6 {
  border-right: 3px solid #5264ae;
}
.latest-post-details {
  border-radius: 20px 4px 20px 20px;
  background: #171b21;
  backdrop-filter: blur(22px);
  padding: 15px;
}
.latest-post-details {
  margin-top: -50px;
}
.redPostBtn {
  font-size: 10px;
}
@media (min-width: 800px) and (max-width: 1024px) {
  .redPostBtn {
    /* font-size: 5px; */
  }
}
</style>
