<template>
  <div v-for="post in posts" :key="post.id" class="post mb-4">
    <div class="post-cover">
      <img :src="post.image" class="w-100" />
    </div>
    <div class="post-body">
      <h5 class="post-title px-2 mb-3">{{ post.title }}</h5>
      <p>{{ post.content }}</p>
      <div class="d-flex justify-content-between align-items-center">
        <PostDetails :date="post.date" :readTime="post.readTime" />
        <div class="redPostBtn">
          <router-link
            :to="post.link"
            class="text-decoration-none text-light p-3"
          >
            تصفح المقال
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
              >
                <path
                  d="M9.16232 1.41L7.55676 0L0.724609 6L7.55676 12L9.16232 10.59L3.94711 6L9.16232 1.41Z"
                  fill="#5264AE"
                />
              </svg>
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostDetails from "./PostDetails.vue";
export default {
  components: {
    PostDetails,
  },
  data() {
    return {
      posts: [
        {
          id: 1,
          image: require("../../assets/imgs/blog/blog-1.png"),
          title: "معاون يطلــق خدمة التعليم الالكتروني",
          content:
            "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.",
          date: "15 . 7 . 2023",
          readTime: "7 د",
          link: "/blog/1",
        },
        {
          id: 2,
          image: require("../../assets/imgs/blog/blog-2.png"),
          title: "تطوير مهارات الطلاب في مجال التكنولوجيا",
          content:
            "في عصر التحول الرقمي، أصبح من الضروري تزويد الطلاب بالمهارات التكنولوجية اللازمة للنجاح في سوق العمل المستقبلي.",
          date: "20 . 7 . 2023",
          readTime: "5 د",
          link: "/blog/2",
        },
        {
          id: 3,
          image: require("../../assets/imgs/blog/blog-3.png"),
          title: "أهمية التعلم المستمر في عالم سريع التغير",
          content:
            "مع تسارع وتيرة التغيير في العالم، أصبح التعلم المستمر ضرورة ملحة للبقاء على اطلاع بأحدث التطورات في مجالات العمل والحياة.",
          date: "25 . 7 . 2023",
          readTime: "6 د",
          link: "/blog/3",
        },
      ],
    };
  },
};
</script>

<style scoped>
.post-body {
  border-radius: 20px 4px 20px 20px;
  background: #171b21;
  backdrop-filter: blur(22px);
  padding: 15px;
}
.post-body {
  margin-top: -50px;
}
.post-title {
  border-right: 3px solid #5264ae;
}
@media (max-width: 767px) {
  .post-title {
    font-size: 18px;
  }
}
.redPostBtn {
  font-size: 14px;
}
</style>
