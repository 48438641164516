<template>
  <div class="home">
    <div class="hero vh-100">
      <div class="container h-100 d-flex justify-center align-items-center">
        <div class="row g-4 w-100 justify-center align-items-center">
          <div class="col-lg-8 order-1 order-lg-0">
            <div
              class="hero-title d-flex flex-column align-items-center align-items-lg-start"
            >
              <div class="text-center text-lg-end">
                <h1><span>مُعاون</span> يُعين ويعاون</h1>
                <p>
                  استعن بخدمات المستقلين المحترفين من شتى أنحاء العالم بنقرة زر
                </p>
              </div>

              <SearchBar v-if="isSmallScreen" />
              <v-form
                class="w-75 d-flex justify-space-between align-items-center"
                v-else
              >
                <input
                  type="search"
                  class="form-control search w-75"
                  placeholder="ابحث عن تخصص أو معاون..."
                />
                <v-btn type="submit" class="btn-custom dark-btn">
                  <i class="fa-solid fa-magnifying-glass"></i>
                  <div>بحث</div>
                </v-btn>
              </v-form>
            </div>
          </div>
          <div
            class="col-lg-4 d-flex justify-content-center order-0 order-lg-1"
          >
            <div class="hero-img">
              <img
                src="../assets/imgs/home/hero-img.svg"
                class="w-100"
                alt="moawen home background"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <MostDemand title="أكثر الخدمات طلبًا من أصحاب المشاريع" />
    <TopFreelancersComponent v-if="isSmallScreen" />
    <div class="container best-experience my-5 py-0 py-lg-5">
      <div class="row">
        <h3 class="text-center mb-4 mb-lg-5">
          تجربة استثنائية في عالم تقديم الخدمات المصغرة
        </h3>
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../assets/imgs/home/experience.svg
        "
            alt="best experience in moawen"
          />
        </div>
      </div>
    </div>
    <div class="contact-fast py-5 my-0 my-lg-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-4 order-2 order-md-0">
            <div>
              <img
                src="../assets/imgs/home/contact-fast.svg"
                alt="contact fast in moawen"
                class="w-100"
              />
            </div>
          </div>
          <div class="col-8 order-0 order-md-2">
            <div>
              <h3>تواصل أسهل وأسرع</h3>
              <p>
                اعقد اجتماعاتك وتواصل مع المستقلين بشكل أسهل وأسرع عبر خاصية
                الاجتماع من داخل المنصة.
              </p>
              <div>
                <router-link to="/register"
                  ><v-btn class="btn-custom dark-btn">
                    <div>سجل كعميل</div>
                  </v-btn></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TopFreelancersComponent v-if="!isSmallScreen" />
    <StartProject :title="title" :desc="desc" :btnTitle="btnTitle" />
  </div>
</template>

<script>
import SearchBar from "@/components/global/SearchBar.vue";
import StartProject from "@/components/startProject/StartProject.vue";
import TopFreelancersComponent from "../components/topFreelancers/TopFreelancersComponent.vue";

import MostDemand from "@/components/mostDemandedServices/MostDemand.vue";
export default {
  components: {
    MostDemand,
    TopFreelancersComponent,
    StartProject,
    SearchBar,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      title: "نفذ مشروعك بكل احترافية ابدأ بالبحث عن معين",
      desc: "حلول ذكية، مهارات متعددة، ومستقلين محترفين من أنحاء العالم، لنسهل عليك <br/> البدء في مشروعك بإمكانيات عالية وفق الميزانية.",
      btnTitle: "ابحث عن معاون",
    };
  },
  computed: {
    isSmallScreen() {
      return this.windowWidth < 992;
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
/* hero */
.hero {
  background-image: url(../assets/imgs/home/hero-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero .hero-title h1 {
  font-size: 50px;
}
.hero .hero-title span {
  background: linear-gradient(88deg, #3f55a2 77.16%, #947ab6 100.14%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
form input.search {
  background-color: #202b51 !important;
  color: #fff;
}
form input.search::placeholder {
  color: rgb(151, 151, 151);
}
/* best experience section */
.best-experience img {
  width: 500px;
}
/* contact-fast */
.contact-fast {
  background: linear-gradient(
    81deg,
    rgba(37, 46, 60, 0.9) -10.46%,
    rgba(12, 22, 37, 0.9) 45.8%
  );
}

@media (max-width: 767px) {
  .hero .hero-img {
    width: 300px;
  }

  .hero .hero-title h1 {
    font-size: 40px;
  }
}
@media (max-width: 556px) {
  .hero .hero-img {
    width: 200px;
  }

  .hero .hero-title h1 {
    font-size: 35px;
  }
  .hero .hero-title p {
    font-size: 14px;
  }
  .contact-fast p {
    font-size: 14px;
  }
  .best-experience img {
    width: 350px !important;
  }
  .best-experience h3 {
    font-size: 20px;
  }
}
</style>
