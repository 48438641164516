<template>
  <div class="most-demanded-servs-comp my-5 py-5">
    <div class="container">
      <div class="d-flex align-items-center">
        <svg
          v-if="!isSmallScreen"
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="22"
          viewBox="0 0 23 22"
          fill="none"
        >
          <circle cx="20" cy="3" r="3" fill="#5264AE" />
          <circle cx="20" cy="11" r="3" fill="#5264AE" />
          <circle cx="20" cy="19" r="3" fill="#5264AE" />
          <circle cx="12" cy="15" r="3" fill="#5264AE" />
          <circle cx="12" cy="7" r="3" fill="#5264AE" />
          <circle cx="3" cy="11" r="3" fill="#5264AE" />
        </svg>
        <h2 class="mb-0" v-if="isSmallScreen">خدمات مميزة</h2>
        <h3 class="ms-2 mb-0" v-else>{{ title }}</h3>
        <!-- Use the prop here -->
      </div>
      <v-slide-group
        v-if="isSmallScreen"
        dir="rtl"
        v-model="model"
        class="mt-3"
      >
        <v-slide-group-item v-for="service in services" :key="service.id">
          <div class="service-card mx-3">
            <div class="service-icon d-flex justify-center align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
              >
                <path :d="service.iconPath" fill="white" />
              </svg>
            </div>
            <p class="mb-0 text-center nowrap">{{ service.name }}</p>
            <p class="mb-0 text-center nowrap">{{ service.projectCount }}</p>
          </div>
        </v-slide-group-item>
      </v-slide-group>
      <div v-else class="row g-4 row-cols-5 mt-3">
        <div v-for="service in services" :key="service.id" class="col">
          <div class="service-card">
            <div class="service-icon d-flex justify-center align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
              >
                <path :d="service.iconPath" fill="white" />
              </svg>
            </div>
            <p class="mb-0 text-center nowrap">{{ service.name }}</p>
            <p class="mb-0 text-center nowrap">{{ service.projectCount }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String, // Define the prop named 'title'
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      model: null,
      services: [
        {
          id: 1,
          name: "مواقع سلة",
          projectCount: "+ 20 مشروع متاح",
          iconPath:
            "M36.2143 11.1429H30.6429V10.7511C30.6429 4.78795 25.8549 0 19.8917 0H19.1953C13.2321 0 8.35714 4.78795 8.35714 10.7511V11.1429H2.78571L0 39H39L36.2143 11.1429ZM11.1429 10.7511C11.1429 6.3375 14.7817 2.78571 19.1953 2.78571H19.8917C24.3054 2.78571 27.8571 6.3375 27.8571 10.7511V11.1429H11.1429V10.7511ZM3.11652 36.2143L5.28415 13.9286H8.35714V17.0886C7.52143 17.5674 6.96429 18.4728 6.96429 19.5C6.96429 21.0408 8.20915 22.2857 9.75 22.2857C11.2908 22.2857 12.5357 21.0408 12.5357 19.5C12.5357 18.4728 11.9786 17.5674 11.1429 17.0886V13.9286H27.8571V17.0886C27.0214 17.5674 26.4643 18.4728 26.4643 19.5C26.4643 21.0408 27.7092 22.2857 29.25 22.2857C30.7908 22.2857 32.0357 21.0408 32.0357 19.5C32.0357 18.4728 31.4786 17.5674 30.6429 17.0886V13.9286H33.7246L35.8922 36.2143H3.11652Z",
        },
        {
          id: 2,
          name: "تطبيقات الجوال",
          projectCount: "+ 15 مشروع متاح",
          iconPath:
            "M27.625 0H11.375C9.06164 0 7.1875 1.87414 7.1875 4.1875V34.8125C7.1875 37.1259 9.06164 39 11.375 39H27.625C29.9384 39 31.8125 37.1259 31.8125 34.8125V4.1875C31.8125 1.87414 29.9384 0 27.625 0ZM19.5 36.5625C18.1669 36.5625 17.0625 35.4581 17.0625 34.125C17.0625 32.7919 18.1669 31.6875 19.5 31.6875C20.8331 31.6875 21.9375 32.7919 21.9375 34.125C21.9375 35.4581 20.8331 36.5625 19.5 36.5625ZM28.4375 29.25H10.5625V4.875H28.4375V29.25Z",
        },
        {
          id: 3,
          name: "تصميم الهوية",
          projectCount: "+ 25 مشروع متاح",
          iconPath:
            "M35.75 0H3.25C1.45507 0 0 1.45507 0 3.25V35.75C0 37.5449 1.45507 39 3.25 39H35.75C37.5449 39 39 37.5449 39 35.75V3.25C39 1.45507 37.5449 0 35.75 0ZM35.75 35.75H3.25V3.25H35.75V35.75ZM29.25 9.75H9.75V13H29.25V9.75ZM29.25 17.875H9.75V21.125H29.25V17.875ZM19.5 26H9.75V29.25H19.5V26Z",
        },
        {
          id: 4,
          name: "التسويق الرقمي",
          projectCount: "+ 18 مشروع متاح",
          iconPath:
            "M39 19.5C39 30.2696 30.2696 39 19.5 39C8.73045 39 0 30.2696 0 19.5C0 8.73045 8.73045 0 19.5 0C30.2696 0 39 8.73045 39 19.5ZM19.5 4.875C11.4147 4.875 4.875 11.4147 4.875 19.5C4.875 27.5853 11.4147 34.125 19.5 34.125C27.5853 34.125 34.125 27.5853 34.125 19.5C34.125 11.4147 27.5853 4.875 19.5 4.875ZM26.8125 19.5L17.0625 26.8125V12.1875L26.8125 19.5Z",
        },
        {
          id: 5,
          name: "كتابة المحتوى",
          projectCount: "+ 22 مشروع متاح",
          iconPath:
            "M35.75 0H3.25C1.45507 0 0 1.45507 0 3.25V35.75C0 37.5449 1.45507 39 3.25 39H35.75C37.5449 39 39 37.5449 39 35.75V3.25C39 1.45507 37.5449 0 35.75 0ZM35.75 35.75H3.25V3.25H35.75V35.75ZM8.9375 8.9375H30.0625V12.1875H8.9375V8.9375ZM8.9375 16.25H30.0625V19.5H8.9375V16.25ZM8.9375 23.5625H30.0625V26.8125H8.9375V23.5625ZM8.9375 30.875H21.9375V34.125H8.9375V30.875Z",
        },
        {
          id: 6,
          name: "تصميم UI/UX",
          projectCount: "+ 30 مشروع متاح",
          iconPath:
            "M19.5 0C8.73045 0 0 8.73045 0 19.5C0 30.2696 8.73045 39 19.5 39C30.2696 39 39 30.2696 39 19.5C39 8.73045 30.2696 0 19.5 0ZM19.5 35.75C10.5237 35.75 3.25 28.4763 3.25 19.5C3.25 10.5237 10.5237 3.25 19.5 3.25C28.4763 3.25 35.75 10.5237 35.75 19.5C35.75 28.4763 28.4763 35.75 19.5 35.75ZM28.4375 19.5C28.4375 24.4259 24.4259 28.4375 19.5 28.4375C14.5741 28.4375 10.5625 24.4259 10.5625 19.5C10.5625 14.5741 14.5741 10.5625 19.5 10.5625C24.4259 10.5625 28.4375 14.5741 28.4375 19.5Z",
        },
        {
          id: 7,
          name: "تطوير الويب",
          projectCount: "+ 28 مشروع متاح",
          iconPath:
            "M35.75 0H3.25C1.45507 0 0 1.45507 0 3.25V35.75C0 37.5449 1.45507 39 3.25 39H35.75C37.5449 39 39 37.5449 39 35.75V3.25C39 1.45507 37.5449 0 35.75 0ZM35.75 35.75H3.25V7.3125H35.75V35.75ZM7.3125 11.375L14.625 18.6875L7.3125 26V11.375ZM31.6875 26L24.375 18.6875L31.6875 11.375V26ZM19.5 23.5625L27.625 31.6875H11.375L19.5 23.5625Z",
        },
        {
          id: 8,
          name: "إدارة وسائل التواصل",
          projectCount: "+ 12 مشروع متاح",
          iconPath:
            "M39 19.5C39 30.2696 30.2696 39 19.5 39C8.73045 39 0 30.2696 0 19.5C0 8.73045 8.73045 0 19.5 0C30.2696 0 39 8.73045 39 19.5ZM20.3125 9.75V17.875H28.4375V21.125H20.3125V29.25H17.0625V21.125H8.9375V17.875H17.0625V9.75H20.3125Z",
        },
        {
          id: 9,
          name: "تصوير فوتوغرافي",
          projectCount: "+ 10 مشروع متاح",
          iconPath:
            "M35.75 7.3125H29.9643L27.625 3.25H11.375L9.03571 7.3125H3.25C1.45507 7.3125 0 8.76757 0 10.5625V32.5C0 34.2949 1.45507 35.75 3.25 35.75H35.75C37.5449 35.75 39 34.2949 39 32.5V10.5625C39 8.76757 37.5449 7.3125 35.75 7.3125ZM19.5 30.0625C14.5741 30.0625 10.5625 26.0509 10.5625 21.125C10.5625 16.1991 14.5741 12.1875 19.5 12.1875C24.4259 12.1875 28.4375 16.1991 28.4375 21.125C28.4375 26.0509 24.4259 30.0625 19.5 30.0625ZM33.3125 14.625H30.0625V11.375H33.3125V14.625Z",
        },
        {
          id: 10,
          name: "الترجمة",
          projectCount: "+ 8 مشروع متاح",
          iconPath:
            "M35.75 0H3.25C1.45507 0 0 1.45507 0 3.25V35.75C0 37.5449 1.45507 39 3.25 39H35.75C37.5449 39 39 37.5449 39 35.75V3.25C39 1.45507 37.5449 0 35.75 0ZM13 31.6875H7.3125V7.3125H13V31.6875ZM31.6875 31.6875H16.25V26H31.6875V31.6875ZM31.6875 22.75H16.25V17.0625H31.6875V22.75ZM31.6875 13.8125H16.25V8.125H31.6875V13.8125Z",
        },
      ],
    };
  },
  computed: {
    isSmallScreen() {
      return this.windowWidth < 992;
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.service-card {
  border-radius: 8px;
  background: radial-gradient(
    52.49% 52.49% at 50% 30.39%,
    rgba(32, 43, 81, 0.6) 0%,
    rgba(32, 43, 80, 0.18) 100%
  );
  backdrop-filter: blur(22px);
  display: flex;
  padding: 16px 35px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
}
.service-card .service-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #5264ae;
}
@media (max-width: 767px) {
  .service-card .service-icon {
    width: 50px;
    height: 50px;
  }
  .service-card .service-icon svg {
    width: 20px;
    height: 20px;
  }
}
</style>
