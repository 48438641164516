<template>
  <div class="hero">
    <div class="container h-100 d-flex justify-center align-items-center">
      <div class="hero-title text-center">
        <h1><span>مُعاون</span> يُعين ويعاون</h1>
        <p>استعن بخدمات المستقلين المحترفين من شتى أنحاء العالم بنقرة زر</p>
        <SearchBar />
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from "../global/SearchBar.vue";
export default {
  components: {
    SearchBar,
  },
};
</script>

<style scoped>
.hero {
  height: 360px;
  background-image: url(../../assets/imgs/home/hero-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767px) {
  .hero {
    height: 300px;
  }
}
.hero .hero-title span {
  background: linear-gradient(88deg, #3f55a2 77.16%, #947ab6 100.14%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
