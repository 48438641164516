<template>
  <app-layout>
    <router-view />
  </app-layout>
</template>

<script>
import appLayout from "@/components/global/AppLayout.vue";
export default {
  // name: "home-page",
  data() {
    return {};
  },
  components: {
    appLayout,
  },
};
</script>

<style>
/* global rules */
html {
  direction: rtl;
}
:root {
  --body: #10141b;
  --dark-bg-color: #343f6f;
  --light-bg-color: #5264ae;
}
body {
  background-color: var(--body) !important;
  color: #fff;
  /* font-family: "Alexandria", sans-serif !important; */
  font-family: "Tajawal", sans-serif !important;
}
@media (max-width: 556px) {
  p {
    font-size: 15px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
  color: #fff !important;
}
.nowrap {
  white-space: nowrap;
}
label {
  margin-bottom: 3px;
}
.btn-custom {
  color: #fff !important;
  padding: 10px 20px;
  border-radius: 5px;
}
.btn-custom.btn-sm {
  display: inline-block;
  width: fit-content !important;
  padding: 0px !important;
  font-size: 12px;
}
.btn-custom.dark-btn {
  background-color: var(--dark-bg-color);
}
.btn-custom.light-btn {
  background-color: var(--light-bg-color);
}
.form-control {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}
/* global rules */
</style>
