import AboutUs from "@/views/AboutUs.vue";
import ClientPage from "@/views/clientPage.vue";
import ContactUs from "@/views/ContactUs.vue";
import FreelancerPage from "@/views/FreelancerPage.vue";
import FreelancersPage from "@/views/FreelancersPage.vue";
import HomePage from "@/views/HomePage.vue";
import ServicesPage from "@/views/ServicesPage.vue";
import ServicePage from "@/views/ServicePage.vue";
export default [
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: {
      title: "الرئيسية",
    },
  },
  {
    path: "/about",
    name: "about",
    component: AboutUs,
    meta: {
      title: "من نحن",
    },
  },
  {
    path: "/freelancers",
    name: "freelancers",
    component: FreelancersPage,
    meta: {
      title: "معاونون",
    },
  },
  {
    path: "/services",
    name: "services",
    component: ServicesPage,
    meta: {
      title: "الخدمات",
    },
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
    meta: {
      title: "تواصل معنا",
    },
  },
  // ======================
  {
    path: "/freelancer", // should be /freelancer/:id
    name: "freelancer",
    component: FreelancerPage,
    meta: {
      // title: "تواصل معنا",
    },
  },
  {
    path: "/client", // should be client/:id
    name: "client",
    component: ClientPage,
    meta: {
      // title: "تواصل معنا",
    },
  },
  {
    path: "/service", // should be service/:id
    name: "service",
    component: ServicePage,
    meta: {
      // title: "تواصل معنا",
    },
  },
];
