<template>
  <div class="app-footer">
    <v-footer
      :app="true"
      class="flex-column px-0 pb-0 position-absolute bottom-0"
    >
      <div class="container">
        <div class="row g-4">
          <div class="col-lg-4 order-0">
            <div>
              <h4>منصة معاون الإلكترونية</h4>
              <p>
                معاون منصة سعودية آمنة، أنشأت لتلبية الطلب المتزايد على خدمات
                المستقلين، وتهدف إلى ربط مقدمي الخدمات من أنحاء العالم بالباحثين
                عنها، وتوفير حلول تضمن سرعة الإنجاز والالتزام بالشروط المتفق
                عليها بين الطرفين
              </p>
            </div>
          </div>
          <!-- =================== -->
          <div class="col-lg-2 col-6 order-3 order-lg-2">
            <div>
              <h4>روابط مهمة</h4>
              <ul class="list-unstyled m-0">
                <li>
                  <router-link :to="{ name: 'about' }">من نحن</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'contact-us' }"
                    >تواصل معنا</router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'blog' }"
                    >المقالات والشروحات</router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'terms-and-conditions' }"
                    >الشروط والأحكام</router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'privacy-policy' }"
                    >سياسة الخصوصية
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-6 order-3 order-lg-2">
            <div>
              <h4>التواصل الاجتماعي</h4>
              <ul class="list-unstyled m-0">
                <li>
                  <a href="#">تويتر</a>
                </li>
                <li><a href="#">انستجرام</a></li>
                <li><a href="#">يوتيوب</a></li>
              </ul>
            </div>
          </div>
          <!-- ================= -->
          <div class="col-lg-3 order-2">
            <h4>ابحث عن معاون</h4>
            <SearchBar v-if="isSmallScreen" />
            <div v-else>
              <v-form>
                <input
                  type="search"
                  class="form-control search"
                  placeholder="ابحث عن تخصص أو معاون..."
                />
                <v-btn type="submit" class="btn-custom dark-btn mt-3">
                  <i class="fa-solid fa-magnifying-glass"></i>
                  <div class="ms-1">بحث</div>
                </v-btn>
              </v-form>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-5 py-3 footer-highlight w-100">
        جميع الحقوق محفوظة لدى منصة معاون 2024
      </div>
    </v-footer>
  </div>
</template>

<script>
import SearchBar from "@/components/global/SearchBar.vue";
export default {
  components: {
    SearchBar,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    isSmallScreen() {
      return this.windowWidth < 992; // Adjust this value based on your definition of "small screen"
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
footer {
  background-color: transparent;
  color: #fff;
}
footer li {
  padding: 5px 0;
}
footer a {
  color: #fff;
  text-decoration: none;
}

form input.search {
  background-color: #202b51 !important;
  color: #fff;
}
form input.search::placeholder {
  color: rgb(151, 151, 151);
}
footer .footer-highlight {
  background-color: #5264ae !important;
}
@media (max-width: 556px) {
  footer h4 {
    font-size: 20px;
  }
}
</style>
