<template>
  <div class="client-page">
    <UserHero
      :user_name="user_name"
      :user_rate="user_rate"
      :category="category"
    />
    <!-- ===================== -->
    <div class="container mb-5 pb-5">
      <div class="row">
        <bio-info :ratingFeedback="ratingFeedback" :rating="rating">
          <template #bio>نبذة عن العميل</template>
          <template #user-services>خدمات اشتراها العميل</template>
          <template #reviews>تقييمات المعاونين</template>
          <template #num-of-reviews>تعليق من المعاونين</template>
        </bio-info>
        <!-- ================ -->
        <user-activity-summary>
          <template #services-provided>المشاريع المنشورة</template>
          <template #services-completed>المشاريع المكتملة</template>
          <template #services-current>المشاريع الجارية</template>
        </user-activity-summary>
      </div>
    </div>
  </div>
</template>

<script>
import UserHero from "@/components/freelancer&client/UserHero.vue";
import UserActivitySummary from "@/components/freelancer&client/UserActivitySummary.vue";
import BioInfo from "@/components/freelancer&client/BioInfo.vue";

export default {
  components: {
    UserHero,
    UserActivitySummary,
    BioInfo,
  },
  data() {
    return {
      user_name: "أحمد فودة",
      user_rate: 4.7,
      category: "العملاء",
    };
  },
};
</script>

<style scoped>
.container {
  margin-top: -40px;
}
</style>
