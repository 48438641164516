<template>
  <v-form
    class="w-100 m-auto p-1 d-flex justify-content-center align-items-center"
  >
    <input
      type="search"
      class="form-control search"
      placeholder="ابحث عن تخصص أو معاون..."
    />
    <v-btn type="submit" class="btn-custom dark-btn">
      <i class="fa-solid fa-magnifying-glass"></i>
      <div>بحث</div>
    </v-btn>
  </v-form>
</template>

<script>
export default {};
</script>

<style scoped>
form {
  background-color: #202b51 !important;
  color: #fff;
  border-radius: 4px;
  background: #202b51;
}
form input.search {
  background-color: transparent !important;
  color: #fff;
}
form input.search::placeholder {
  color: rgb(151, 151, 151);
}
@media (min-width: 767px) {
  form input.search::placeholder {
    font-size: 14px;
  }
}
</style>
