<template>
  <div class="not-found-page">
    <HeroComponent />
    <div class="container py-5">
      <div class="row">
        <div class="col-12">
          <div
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <img src="../assets/imgs/not-found.svg" alt="not-found" />
            <h1 class="text-center my-3">عفوًا يبدو انك فقدت الوجهة</h1>
            <p class="text-center">
              هذه الصفحة تعني أنك حاولت الدخول لصفحة ليست موجودة ، لا تقلق يمكنك
              دائمًا العودة للصفحة الرئيسية
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeroComponent from "@/components/freelancers&projects/HeroComponent.vue";
export default {
  name: "NotFound",
  components: {
    HeroComponent,
  },
};
</script>
<style scoped>
.not-found-page img {
  width: 300px;
}
</style>
