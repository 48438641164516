import { createRouter, createWebHistory } from "vue-router";

import mainRoutes from "./routes/mainRoutes";
import authRoutes from "./routes/authRoutes";
import blogRoutes from "./routes/blogRoutes";
import NotFound from "@/views/NotFound.vue";
import UserDashboard from "@/layouts/UserDashboard.vue";
import UserDashboardRoutes from "./routes/userDashboardRoutes";
import TermsAndConditions from "@/views/TermsAndConditions.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
const routes = [
  ...mainRoutes,
  ...authRoutes,
  ...blogRoutes,
  // ==================
  {
    path: "/dashboard",
    name: "dashboard",
    component: UserDashboard,
    children: [...UserDashboardRoutes],
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: TermsAndConditions,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // طلعت عين امي ==================================
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(_, __, ___) {
    // Always scroll to top without transition
    return { top: 0, behavior: "instant" };
  },
});

export default router;
