import ClientChat from "@/components/client-dash/ClientChat.vue";
import ClientHome from "@/components/client-dash/ClientHome.vue";
import ClientProfile from "@/components/client-dash/ClientProfile.vue";
import ClientRequest from "@/components/client-dash/ClientRequest.vue";
import ClientReviews from "@/components/client-dash/ClientReviews.vue";
import ClientSecurity from "@/components/client-dash/ClientSecurity.vue";
import FreelancerChat from "@/components/freelancer-dash/FreelancerChat.vue";
import FreelancerHome from "@/components/freelancer-dash/FreelancerHome.vue";
import FreelancerPortfolio from "@/components/freelancer-dash/FreelancerPortfolio.vue";
import FreelancerProfile from "@/components/freelancer-dash/FreelancerProfile.vue";
import FreelancerReviews from "@/components/freelancer-dash/FreelancerReviews.vue";
import FreelancerSecurity from "@/components/freelancer-dash/FreelancerSecurity.vue";
import FreelancerServices from "@/components/freelancer-dash/FreelancerServices.vue";
// ====================
export default [
  //freelancer
  {
    path: "home",
    name: "dashboard-freelancer-home",
    component: FreelancerHome,
  },
  {
    path: "services",
    name: "dashboard-freelancer-services",
    component: FreelancerServices,
  },
  {
    path: "chat",
    name: "dashboard-freelancer-chat",
    component: FreelancerChat,
  },
  {
    path: "portfolio",
    name: "dashboard-freelancer-portfolio",
    component: FreelancerPortfolio,
  },
  {
    path: "reviews",
    name: "dashboard-freelancer-reviews",
    component: FreelancerReviews,
  },
  {
    path: "security",
    name: "dashboard-freelancer-security",
    component: FreelancerSecurity,
  },
  {
    path: "profile",
    name: "dashboard-freelancer-profile",
    component: FreelancerProfile,
  },
  // ====================
  //client
  {
    path: "home",
    name: "dashboard-client-home",
    component: ClientHome,
  },
  {
    path: "requests",
    name: "dashboard-client-requests",
    component: ClientRequest,
  },
  {
    path: "chat",
    name: "dashboard-client-chat",
    component: ClientChat,
  },
  {
    path: "reviews",
    name: "dashboard-client-reviews",
    component: ClientReviews,
  },
  {
    path: "security",
    name: "dashboard-client-security",
    component: ClientSecurity,
  },
  {
    path: "profile",
    name: "dashboard-client-profile",
    component: ClientProfile,
  },
];
