<template>
  <div class="row my-md-16 my-8 justify-content-center g-4">
    <h4 class="text-center mb-8">
      لمزيد من الاستفسارات أو المعلومات يمكنكم التواصل معنا عبر
    </h4>
    <div class="col-sm-6 col-lg-3">
      <div class="channel">تواصل عبر البريد الإلكتروني</div>
    </div>
    <div class="col-sm-6 col-lg-3">
      <div class="channel">أرقام الدعم الفني</div>
    </div>
    <div class="col-sm-6 col-lg-3">
      <div class="channel">مواقع التواصل الإجتماعي</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.channel {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #5264ae;
  background: radial-gradient(
    52.49% 52.49% at 50% 30.39%,
    rgba(32, 43, 81, 0.6) 0%,
    rgba(32, 43, 80, 0.18) 100%
  );
  backdrop-filter: blur(22px);
}
@media (max-width: 768px) {
  h4 {
    font-size: 20px;
  }
}
</style>
