import SinglePost from "@/components/blog/SinglePost.vue";
import HomeBlog from "@/views/HomeBlog.vue";
export default [
  {
    path: "/blog",
    name: "blog",
    component: HomeBlog,
    meta: {
      title: "",
    },
  },
  {
    path: "/single-post",
    name: "single-post",
    component: SinglePost,
    meta: {
      title: "",
    },
  },
];
