<template>
  <div class="privacy-policy-page">
    <TermsAndPrivacy :heading="heading" :description="description" />
    <content-component>
      <template #content>
        <h5>أولا: - المقدمة</h5>
        <p>
          <span class="fw-bold"
            >معاون والتي قد يشار إليه فيما بعد بكلمة ("نحن" أو "اننا"، أو الضمير
            "نا") يقصد به موقع معاون.</span
          >
          تحكم وتنظم هذه السياسة كيفية استخدامك لموقع معاون وتوضح واجباتنا
          وحقوقنا فيما يتعلق بجمع المعلومات التي تنتج عن استخدامك لخدمتنا
          وحمايتها والكشف عنها ، نحن نقوم باستخدام بياناتك لغرض توفير الخدمة
          وتحسينها، وباستخدامك لمعاون فهذا إقرار منك بالموافقة على سياسة
          الخصوصية.
        </p>
        <h5>ثانيا: - التعريفات</h5>
        <p>
          <span class="fw-bold">البيانات الشخصية</span> تعني البيانات المتعلقة
          بالأشخاص الذين يمكن تحديد هويتهم من خلال تلك البيانات.
          <br />
          <span class="fw-bold">بيانات الاستخدام</span> هي بيانات يتم تجميعها
          تلقائيًا إما عند استخدام الخدمة أو من خلال البنية التحتية للخدمة نفسها
          (على سبيل المثال مدة زيارة الصفحة).
          <br />
          <span class="fw-bold">ملفات تعريف الاترباط (كوكيز)</span> هي ملفات
          صغيرة مخزنة على جهازك (جهاز الهاتف أو جهاز الحاسوب وغيرها).
          <br />
          <span class="fw-bold">موضوع البيانات</span> هو أي مستخدم لمعاون يكون
          صاحب البيانات الشخصية.
          <br />
          <span class="fw-bold">المستخدم</span> هو الشخص الذي يستخدم موقع معاون
          وهو نفسه موضوع البيانات الشخصية، سواء كان بائع أم مشتري أم زائر.
        </p>
        <h5>ثالثا: - أنواع البيانات التي يتم جمعها</h5>
        <p>
          <span class="fw-bold">بيانات شخصية </span>
          <br />
          أثناء استخدامك لخدمات موقع معاون قد نطلب منك تزويدنا ببعض المعلومات
          الشخصية التي يمكن استخدامها للاتصال بك أو تحديد هويتك ("البيانات
          الشخصية"). قد تتضمن معلومات تحديد الشخصية على سبيل المثال لا الحصر:
          <br />
          (أ) عنوان البريد الإلكتروني.
          <br />
          (ب) الاسم الأول واسم العائلة.
          <br />
          (ج) رقم الهاتف.
          <br />
          (د) العنوان والدولة والمنطقة والرمز البريدي والمدينة.
          <br />
          (هـ) ملفات تعريف الارتباط والبيانات الناتجة عن استخدام الخدمة.
          <br />
          قد نستخدم بياناتك الشخصية لإمدادك بالنشرات الإخبارية والمواد التسويقية
          أو الترويجية وغيرها من المعلومات التي قد تهمك. ويمكنك اختيار عدم تلقي
          أي من هذه الرسائل أو جميعها من خلال اتباع رابط إلغاء الاشتراك.
          <br />
          <span class="fw-bold">بيانات الاستخدام </span>
          <br />
          قد نقوم بجمع المعلومات التي يرسلها متصفحك كلما قمت بزيارة الخدمة أو
          عند وصولك إلى الخدمة بواسطة أو من خلال جهاز محمول وتسمى هذه المعلومات
          ("بيانات الاستخدام").
          <br />
          قد تتضمن بيانات الاستخدام معلومات مثل عنوان بروتوكول الإنترنت (IP
          Address) الخاص بجهاز الكمبيوتر ونوع المتصفح وإصدار المتصفح وصفحات
          الخدمة التي تزورها ووقت وتاريخ زيارتك والوقت الذي قضيته في تلك الصفحات
          والمعرفات الفريدة للأجهزة وبيانات أخرى مُمَيزة.
          <br />
          <span class="fw-bold">تتبع بيانات ملفات تعريف الارتباط </span>
          <br />
          قد نستخدم ملفات تعريف الارتباط (الكوكيز) وأساليب التتبع المشابهة لها
          لتتبع نشاطك عند استخدام خدمتنا ونحتفظ بمعلومات معينة.
          <br />
          يمكنك ضبط المستعرض الخاص بك لرفض جميع ملفات تعريف الارتباط أو للإشارة
          إلى وقت إرسال ملف تعريف الارتباط. وعلى أي حال، إذا كنت لا تقبل ملفات
          تعريف الارتباط فقد لا تتمكن من استخدام بعض مميزات خدمتنا.
        </p>
        <h5>رابعا: - استخدام البيانات</h5>
        <p>
          نحن في معاون نقوم باستخدام البيانات الخاصة بمستخدمي معاون وذلك للأغراض
          الآتية:
          <br />
          (أ) لتوفير خدماتنا وصيانتها
          <br />
          (ب) لإعلامك بالتغييرات التي تطرأ على خدمتنا
          <br />
          (ج) للسماح لك بالمشاركة في الميزات التفاعلية لخدمتنا عندما تختار
          القيام بذلك
          <br />
          (د) لتوفير الدعم للعملاء
          <br />
          (هـ) لجمع التحليلات أو المعلومات المفيدة حتى نتمكن من تحسين خدمتنا
          <br />
          (و) لمراقبة استخدام خدمتنا
          <br />
          (ز) الكشف عن المشاكل التقنية ومعالجتها ومنعها
          <br />
          (ح) لتحقيق أي غرض آخر كنت توفر هذه البيانات من أجله
          <br />
          (ط) لتنفيذ التزاماتنا والتأكيد على حقوقنا الناشئة عن أي عقود يتم
          إبرامها بيننا وبينك بما في ذلك الفاتورة والتحصيل.
          <br />
          (ي) لتزويدك بإشعارات حول حسابك و / أو اشتراكك، بما في ذلك إشعارات
          انتهاء الصلاحية والتجديد، وإرشادات البريد الإلكتروني، وما إلى ذلك.
          <br />
          (ك) لتزويدك بالأخبار والعروض الخاصة والمعلومات العامة حول الخدمات
          والأحداث الأخرى التي نقدمها والتي تشبه تلك التي قمت بشرائها أو
          الاستفسار عنها ما لم تكن قد اخترت عدم تلقي هذه المعلومات،
          <br />
          (م) استخدام تلك البيانات لأي غرض آخر بعد الحصول على موافقتك.
        </p>
        <h5>خامسا: - الاحتفاظ بالبيانات</h5>
        <p>
          نحتفظ ببياناتك الشخصية فقط عندما يكون ذلك ضروريًا للأغراض المنصوص
          عليها في سياسة الخصوصية هذه. وسنحتفظ ببياناتك الشخصية ونستخدمها بالقدر
          اللازم للامتثال لالتزاماتنا القانونية (على سبيل المثال، إذا كنا
          مطالبين بالاحتفاظ ببياناتك للامتثال للقوانين المعمول بها) وعند حل
          النزاعات ولإنفاذ اتفاقياتنا وسياساتنا القانونية.
        </p>
        <h5>سادسا: - الإفصاح عن البيانات والمعلومات</h5>
        <p>
          قد يتم نقل معلوماتك، بما في ذلك البيانات الشخصية، إلى أجهزة أخرى
          موجودة خارج وطنك أو بلدك أو منطقتك أو أي سلطة قضائية حكومية أخرى حيث
          قد تختلف قوانين حماية البيانات عن قوانين سلطتك القضائية.
          <br />
          سوف يتخذ معاون جميع الخطوات اللازمة بشكل مقبول لضمان أن يتم التعامل مع
          بياناتك بطريقة آمنة ووفقًا لسياسة الخصوصية هذه ولن يتم نقل بياناتك
          الشخصية إلى اي منظمة أو بلد ما لم تكن هناك ضوابط كافية سارية تتضمن أمن
          بياناتك وغيرها من المعلومات الشخصية.
          <br />
          ويحق لنا في معاون أن نكشف عن المعلومات الشخصية التي نجمعها أو تقوم
          بتوفيرها لنا في أي حالة من الحالات الآتية: -
          <br />
          (أ) الكشف عن هذه المعلومات والبيانات من أجل تطبيق القانون.
          <br />
          (ب) المعاملات التجارية إذا كنا نحن أو الشركات التابعة لنا مشاركين في
          عملية اندماج أو استحواذ أو بيع أصول فقد يتم نقل بياناتك الشخصية.
          <br />
          (ج) حالات اخرى. قد نكشف فيها عن معلوماتك أيضًا:
          <br />
          (1) إلى الشركات والمؤسسات التابعة لنا؛
          <br />
          (2) للمتعاقدين ومقدمي الخدمات والأطراف الثالثة الأخرى الذين نستخدمهم
          لدعم أعمالنا؛
          <br />
          (5) لأي غرض آخر يكشف عنه من قبلنا عند تقديم المعلومات؛
          <br />
          (6) بموافقتك في أي حالات أخرى؛
          <br />
          (7) إذا كنا نعتقد أن الإفصاح ضروري أو مناسب لحماية حقوق أو ممتلكات أو
          سلامة معاون أو عملائنا أو غيرهم
        </p>
        <h5>سابعا: - مزودي الخدمة</h5>
        <p>
          يجوز لنا توظيف شركات أو أفراد لتيسير خدمتنا ("موفرو الخدمة") أو لتقديم
          الخدمة نيابة عنا أو أداء الخدمات المتعلقة بالخدمة أو مساعدتنا في تحليل
          كيفية استخدام خدمتنا، وسيكون حق الوصول إلى بياناتك الشخصية فقط لأداء
          هذه المهام نيابة عنا، وهم ملزمون بعدم الكشف عنها أو استخدامها لأي غرض
          آخر، ويسري هذا الأمر في حال انتقال ملكية معاون إلى الغير.
        </p>
        <h5>ثامنا: - التحديثات</h5>
        <p>
          قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. سنقوم بإخطارك في
          حال القيام بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه
          الصفحة. وكذلك سنقوم بإعلامك عبر البريد الإلكتروني و/أو بإشعار بارز على
          خدمتنا قبل أن يصبح التغيير ساري النفاذ.
        </p>
      </template>
    </content-component>
  </div>
</template>
<script>
import TermsAndPrivacy from "@/components/terms-and-privacy/TermsAndPrivacyTopComp.vue";
import ContentComponent from "@/components/terms-and-privacy/ContentComponent.vue";
export default {
  name: "PrivacyPolicy",
  components: {
    TermsAndPrivacy,
    ContentComponent,
  },
  data() {
    return {
      heading: "سياسة الخصوصية",
      description:
        "أهلا وسهلا بكم في معاون، ونشكركم على منحنا جزءا من وقتكم لقراءة هذه السياسة.",
    };
  },
};
</script>
<style scoped>
.content {
  border-radius: 12px;
  border: 1px solid #5264ae;
  background: #171b21;
  backdrop-filter: blur(22px);
  padding: 24px;
}
.content p {
  line-height: 2;
}
.content h5 {
  font-weight: bold;
  padding-right: 8px;
  margin-top: 32px;
  margin-bottom: 10px;
  border-right: 4px solid rgba(82, 100, 174, 1);
}
@media (max-width: 768px) {
  .content {
    padding: 15px;
  }
  .content h5 {
    line-height: normal;
  }
  .content h4 {
    font-size: 18px;
  }
}
@media (max-width: 556px) {
  .container {
    width: 95%;
  }
}
.quoted-text {
  quotes: "" " " "";
}
.quoted-text::before,
.quoted-text::after,
.quoted-text q::before,
.quoted-text q::after {
  color: #5264ae; /* Change this to your desired color */
}
.content ul > li {
  position: relative;
  margin: 8px 0;
  padding-right: 10px;
}
.content ul > li::before {
  padding-left: 10px;
  position: absolute;
  right: -5px;
  top: -14px;
  font-size: 2em;
  content: "\2022";
  color: #fff;
}
</style>
