<template>
  <div class="contact-page">
    <HeroBreadCrumbs :items="items" :details="details" />
    <!-- contact-form section -->
    <v-form class="container my-5">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-part">
            <div>
              <label>الاسم الكامل</label>
              <v-text-field
                dir="rtl"
                type="text"
                density="compact"
              ></v-text-field>
            </div>
            <div>
              <label>موضوع الرسالة</label>
              <v-text-field
                dir="rtl"
                type="text"
                density="compact"
              ></v-text-field>
            </div>
            <div>
              <label>البريد الإلكتروني</label>
              <v-text-field
                dir="rtl"
                type="email"
                density="compact"
              ></v-text-field>
            </div>
            <div>
              <label>رقم الهاتف</label>
              <v-text-field
                dir="rtl"
                type="tel"
                density="compact"
              ></v-text-field>
            </div>
            <div>
              <label>الرسالة</label>
              <v-textarea
                dir="rtl"
                name="input-7-1"
                variant="filled"
                auto-grow
              ></v-textarea>
            </div>
            <v-btn type="submit" class="btn-custom light-btn px-5">
              <div>إرسال</div>
            </v-btn>
          </div>
        </div>
        <div class="col-md-6 d-none d-lg-flex">
          <div>
            <img
              src="../assets/imgs/contact/contact.png
              "
              class="w-100"
              alt=""
            />
          </div>
        </div>
      </div>
    </v-form>
    <!-- contact-form section -->
    <div class="container my-5">
      <div class="row contact-section position-relative mx-0">
        <div class="contact-overlay d-flex align-items-center">
          <div class="w-100">
            <h4 class="d-none d-lg-block">
              حلول ذكية، مهارات متعددة، ومستقلين محترفين من أنحاء العالم؛ لنسهل
              عليك <br />
              البدء في مشروعك بإمكانيات عالية وفق ميزانيتك
            </h4>
            <h2 class="text-center d-lg-none">
              نفذ مشروعك بكل احترافية ابدأ بالبحث عن معين
            </h2>
            <p class="text-center d-lg-none">
              حلول ذكية، مهارات متعددة، ومستقلين محترفين من أنحاء العالم، لنسهل
              عليك البدء في مشروعك بإمكانيات عالية وفق الميزانية.
            </p>
            <div class="d-flex justify-content-center justify-content-lg-start">
              <v-btn class="btn-custom dark-btn mt-2 mt-lg-3">
                <span>ابحث عن معاون</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeroBreadCrumbs from "@/components/heroBreadCrumbs/HeroBreadCrumbs.vue";

export default {
  components: {
    HeroBreadCrumbs,
  },
  data() {
    return {
      items: [
        {
          title: "الرئيسية",
          disabled: false,
          href: "/",
        },
        {
          title: "تواصل معنا",
          disabled: true,
          href: "",
        },
      ],
      details: "لأي استفسارات او مقترحات يسعدنا تواصلك معنا",
    };
  },
};
</script>

<style scoped>
.form-part {
  border-radius: 8px;
  border: 1px solid #5264ae;
  background: #171b21;
  backdrop-filter: blur(22px);
  padding: 20px 15px;
}
/* ------------------- */
.contact-section {
  height: 300px;
  background-image: url("../assets/imgs/contact/contact-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
}
@media (max-width: 767px) {
  .contact-section {
    height: 250px;
  }
}
.contact-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 12px;
  border: 1px solid #5264ae;
  background: linear-gradient(
    81deg,
    rgba(37, 46, 60, 0.9) -10.46%,
    rgba(12, 22, 37, 0.9) 45.8%
  );
}
</style>
