<template>
  <div class="freelancer-services dash-main-comp">
    <div>
      <div>services</div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.dash-main-comp {
  background-color: #10141b;
  padding: 30px 15px;
  height: 100%;
}
</style>
