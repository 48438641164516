<template>
  <div class="terms-and-conditions-page">
    <TermsAndPrivacy :heading="heading" :description="description" />
    <content-component>
      <template #content>
        <p>
          يعمل معاون كموقع متخصص في الوساطة ما بين مقدمي الخدمات (البائعين)
          وطالبي هذه الخدمات (المشترين) مقابل عمولة يحصل عليها كما هو موضح
          ومذكور في العقود والسياسات الأخرى، ويتم تقديم الخدمة وفق الشروط
          والأحكام المنصوص عليها في هذه الوثيقة وسياسة الخصوصية والعقود
          والسياسيات الأخرى المتفق عليها بين الأطراف.
        </p>
        <h5>أولاً: تعريفات المصطلحات</h5>
        <p class="quoted-text">
          في هذه الاتفاقية، وما لم يقتضي السياق خلاف ذلك يكون للمصطلحات التالية
          المعاني المشار إليها.
          <br />
          <q> الموقع </q> أو <q> نحن </q> أو <q> ضمير المتكلم </q> أو
          <q> ضمير الملكية </q> : يشير إلى موقع معاون.
          <br />
          <q> المستخدم </q> أو <q> أنت </q> أو <q> ضمير الملكية للمخاطب </q> :
          يعني الشخص الذي يزور معاون، أو يستخدمه، أو يُسجل عضوية بالموقع.
          <br />
          <q> المشترك </q> يشير إلى كل شخص يشترك معنا من خلال تسجيل عضوية في
          الموقع الخاص بنا وهو إما أن يكون بائع وأما أن يكون مشتري.
          <br />
          <q> الاتفاقية </q> تشير إلى هذه الوثيقة وما تتضمنه من شروط وأحكام،
          وكذلك سياسة الخصوصية، وكافة سياسات تقديم الخدمات، وكافة الاتفاقيات
          والسياسات المكملة والمنفذة لاتفاقك معنا، بالإضافة لأي سياسة أخرى أو
          عقود مضافة من طرفنا.
          <br />
          <q> أطراف الاتفاقية </q> يشير إلى كافة الأشخاص الذين يخضعون للشروط
          والأحكام المنصوص عليها في هذه الاتفاقية، بما تتضمنه من حقوق والتزامات.
        </p>
        <h5>
          ثانيا: الطبيعة القانونية لهذه الاتفاقية وللاتفاقيات الأخرى المكملة
        </h5>
        <ul class="list-unstyled">
          <li>
            تعد هذه الاتفاقية وملحقاتها بمثابة الاتفاق الكامل والنهائي بين موقع
            معاون من ناحية، وأي شخص يقوم بزيارة معاون أو يستخدمه أو يسجل عضوية
            فيه.
          </li>
          <li>
            تعد هذه الاتفاقية عقدًا صحيحًا مكتملاً الشروط والأركان القانونية،
            نافذًا في مواجهة جميع الأطراف المذكورة، وأحكامه والتزاماته ملزمة لهم
            جميعًا، ولا يجوز لأحد التحلل منها أو إنهائها طالما أنتجت آثارها
            القانونية.
          </li>
          <li>
            يقر جميع الأطراف أن هذه الاتفاقية تشكل كامل الاتفاق بينهم، وقد
            اتفقوا على أنهم لم يعتمدوا على أي تأكيدات، شفوية كانت أم كتابية، في
            الموافقة على هذه الاتفاقية بخلاف الأحكام المبينة فيها.
          </li>
          <li>
            يعد وصف الخدمات والصفحات الفرعية التي تم إعدادها من قِبل موقع معاون
            جزءا لا يتجزأ من هذه الاتفاقية.
          </li>
          <li>
            تعتبر العقود المكملة لهذه الاتفاقية جزء لا يتجزأ منها، ويسري عليها
            ما يسري على هذه الاتفاقية من شروط وأحكام، وترتبط بهذه الاتفاقية
            ارتباطًا لا يقبل التجزئة وجودًا وعدمًا.
          </li>
          <li>
            يكون للتعديلات الواردة على هذه الاتفاقية نفس الحكم والأثر القانوني
            لهذه الاتفاقية.
          </li>
        </ul>
        <h5>ثالثا: الموافقة والأهلية القانونية</h5>
        <ul class="list-unstyled">
          تقر بأن لديك الأهلية القانونية اللازمة للإبرام والموافقة على هذه
          الاتفاقية، وأن لديك الصلاحيات القانونية الكاملة غير المقيدة طبقًا
          للشروط التالية :

          <li>
            يشترط في من يسجل أو يستخدم موقع معاون أن يبلغ من العمر ثمانية عشر
            عاما فأكثر، وأن يكون كامل الأهلية النظامية والشرعية وفقا لأحكام
            الأنظمة المعمول بها والنافذة في المملكة العربية السعودية.
          </li>
          <li>
            يشترط في من يستخدم موقع معاون أن يتوافر فيه الأهلية القانونية
            اللازمة لإبرام العقود، وألا يكون مصاب بأي من عوارض أو موانع الأهلية،
            وموقع معاون غير مسؤول عن التحقق من أهلية أيًا من مستخدمي الموقع
          </li>
          <li>
            باستخدامك خدمات الموقع فأنت توافق على هذه الاتفاقية، وتقر بأنك
            ملزمًا قانونًا بالشروط والأحكام المنصوص عليها في هذه الوثيقة أو
            تعديلاتها.
          </li>
          <li>
            يعد استخدامك للموقع أو زيارته أو دخولك معنا في أي اتفاقية بمثابة
            توقيع إلكتروني على هذه الاتفاقية.
          </li>
        </ul>
        <h5>رابعا: الاتصالات</h5>
        <ul class="list-unstyled">
          <li>
            أنت توافق على تلقي الاتصالات منا، وسوف نتواصل معك عن طريق البريد
            الالكتروني أو رقم الجوال أو عن طريق نشر إشعارات عبر الموقع أو من
            خلال خدمات الموقع الأخرى.
          </li>
          <li>
            أنت توافق على أن جميع الاتفاقات والإشعارات والإفصاحات وغيرها من
            الاتصالات التي نقدمها لك إلكترونيًا تستوفي كافة الشروط القانونية كما
            لو كانت هذه الاتصالات خطية، وتقوم مقامها في إنتاج آثارها القانونية.
          </li>
          <li>
            إذا قررت في أي وقت أنك لا ترغب في تلقي رسائل ترويجية، يمكنك تعطيل
            تلقي هذه الرسائل عن طريق مراسلتنا، ولكن في هذه الحالة لا نضمن تمتعك
            بخدماتنا بشكل كامل.
          </li>
        </ul>
        <h5>خامسا: حقوق الملكية الفكرية</h5>
        <ul class="list-unstyled">
          <li>
            الموقع والأفكار المُعبَر عنها داخله، هي حقوق ملكية فكرية خالصة لنا،
            وأي تقليد أو اقتباس للموقع أو بعض خدماته (بما في ذلك على سبيل المثال
            الأفكار والنصوص والرموز والبرمجيات) يعد انتهاكًا لحقوق التأليف
            والنشر الخاصة بنا، وسنقوم معه باتخاذ كافة الإجراءات القانونية ضد
            مرتكب الانتهاكات المذكورة.
          </li>
          <li>
            جميع المحتويات المضمنة أو المتاحة ضمن خدمات الموقع مثل (النصوص
            والشعارات والصور والرسوم البيانية والتسجيلات الصوتية وأيقونات
            الأزرار والمحتويات الرقمية والمواد التي يتم إعادة تحميلها والبرمجيات
            وتجميع البيانات) هي ملك للموقع الخاص بنا، وهي محمية من قِبل قوانين
            الدولة المحلية " الأنظمة ذات العلاقة السارية في المملكة العربية
            السعودية" وقوانين حقوق التأليف والنشر الدولية.
          </li>
        </ul>
        <h5>سادسا: التراخيص الممنوحة</h5>
        <ul class="list-unstyled">
          <li>
            يمنحك الموقع ترخيصًا محدودًا وغير حصري لاستخدام موقع " معاون "،
            ويحظر عليك التنازل عن هذا الترخيص إلى الغير.
          </li>
          <li>
            لا يشمل هذا الترخيص إعادة بيع أو أي استخدام تجاري لأي من خدماتنا أو
            محتوياتها.
          </li>
          <li>
            لا يشمل هذا الترخيص أي نسخ للمعلومات المتوفرة عن الحساب لصالح الغير،
            أو أي استخدام لوسائل استخراج البيانات أو أي استخدام لأدوات جمع
            واستخلاص البيانات المماثلة.
          </li>
          <li>
            لا يجوز لك إعادة إنتاج أو عمل نسخة مطابقة لهذا الموقع، أو نسخ أو بيع
            أو إعادة بيع أي جزء منه، أو استخدامه بصورة مغايرة في أغراض الاستغلال
            التجاري أو غير التجاري له دون الحصول على موافقة كتابية صريحة من
            الموقع.
          </li>
          <li>
            لا يجوز لك استخدام أي علامات وصفية أو أي "نص مخفي" آخر يستغل اسم
            الموقع أو علاماته التجارية بدون موافقة كتابية صريحة من إدارة الموقع.
          </li>
          <li>
            لا يجوز لك بأي شكل من الأشكال التواصل الخارجي مع الطرف الذي تتعامل
            معه عبر الموقع ويجب أن تكون جميع التعاملات من خلال موقع معاون.
          </li>
          <li>
            لا يجوز لك إساءة استخدام خدماتنا بأي شكل من الأشكال، ومن ذلك السب أو
            القذف أو التحقير أو إهانة الأديان أو الأعراف وغير ذلك.
          </li>
          <li>
            تنتهي التراخيص الممنوحة من قِبلنا إذا لم تلتزم بشروط الاستخدام هذه
            أو أي شروط خدمة أخرى.
          </li>
        </ul>
        <h5>سابعا: المسئولية القانونية لأطراف الاتفاقية</h5>
        <ul class="list-unstyled">
          <li>أنت توافق صراحة على أنك تستخدم الموقع على مسئوليتك الشخصية.</li>
          <li>
            أنت مسئولاً عن المحافظة على استخدام الموقع بكل جدية ومصداقية وتكون
            ملزمًا بالتعويض عن أي خسائر أو أضرار قد تلحق بالموقع نتيجة أي
            استخدام غير شرعي أو غير مفوض من قِبلنا.
          </li>
          <li>
            أنت مسئولاً عن المحافظة على استخدام الموقع بكل جدية ومصداقية وتكون
            ملزمًا بالتعويض عن أي خسائر أو أضرار قد تلحق بالموقع نتيجة أي
            استخدام غير شرعي أو غير مفوض من قِبلنا.
          </li>
          <li>
            فيما يتعلق بالتعاملات المالية لا نكون مسئولين عن التحويلات المالية
            والخطأ في التحويلات وكذلك الحماية من الاختراق.
          </li>
          <li>
            أنت توافق على عدم إرسال أي رسائل تتسم بالتمييز العنصري أو تحتوي على
            سب وقذف أو ألفاظ نابية أو صور خليعة أو فاحشة، أو تتسم بوجه عام بأنها
            ذات "ذوق رديء".
          </li>
          <li>
            في حالة مخالفة المستخدم لأي من شروط أو أحكام هذه الاتفاقية فيكون من
            حقنا اتخاذ إجراء إداري داخل حساب المستخدم يتمثل في وقف العضوية لفترة
            من الزمن أو عمل حظر دائم للمستخدم المخالف، ولا يحق له في هذه الحالة
            التسجيل مرة أخرى إلا بموافقة صريحة من فريق الدعم الفني.
          </li>
          <li>
            أنت توافق على تعويض الموقع وإبراء مسئوليته والدفاع عنه ضد كافة
            الدعاوى والمطالبات التي قد يرفعها أو يطالب بها الغير نتيجة استخدامك
            للموقع، أو بسبب قيامك بانتهاك هذه الشروط والبنود أو بالإخلال بحقوق
            المستخدمين الآخرين.
          </li>
          <li>
            إذا قمت بانتهاك اتفاقية المستخدم هذه، فإن الموقع يحتفظ بحقه في
            استعادة أية مبالغ مستحقة عليك، وأي خسائر أو أضرار تسببت فيها ولمعاون
            الحق في اتخاذ الإجراءات القانونية أو اللجوء للمحاكم المختصة لرفع
            دعاوى مدنية أو جنائية ضدك.
          </li>
          <li>
            الموقع لا يضمن اتخاذه إجراءات ضد كل الانتهاكات التي قد تحدث لاتفاقية
            الاستخدام هذه، ولا يعني عدم اتخاذنا للإجراءات القانونية في أي من
            حالات الانتهاك تنازلاً عن حقنا في اتخاذ هذه الإجراءات في أي وقت نراه
            مناسبًا.
          </li>
          <li>
            يجب عليك الالتزام بجميع القوانين واللوائح المعمول بها داخل الدولة
            التي تستخدم الموقع من خلالها وذلك فيما يتعلق باستخدامك للموقع،
            وتتحمل كافة المسئوليات الناشئة في حالة إخلالك بهذه القوانين أو
            اللوائح، كما تلتزم بجميع الشروط والأحكام المنصوص عليها في هذه
            الاتفاقية.
          </li>
          <li>
            أنت تقر بأنك سوف تقوم بإخطارنا في حالة وجود أي منشورات أو مواد أو
            معاملات يبدو أنها تنتهك اتفاقية الاستخدام.
          </li>
        </ul>
        <h5>ثامنا: -الإنهاء</h5>
        <ul class="list-unstyled">
          <li>
            نحتفظ بحقنا في الإنهاء الفوري لاستخدامك موقع معاون في حال قيامك بخرق
            بنود هذه الشروط والأحكام أو في حال اعتقدنا بناء على أسباب منطقية أنك
            من الممكن أن تقوم بخرق بنود هذه الشروط والأحكام أو في حال قيامك
            بسلوك نرى أنه وبناء على تقديرنا المطلق غير مقبول.
          </li>
          <li>
            يمكننا إنهاء حسابك في معاون بناء على حكم قضائي صادر عن محكمة مختصة.
          </li>
        </ul>
        <h5>تاسعا: التعديلات</h5>
        <ul class="list-unstyled">
          <li>
            يلتزم مستخدم موقع معاون بما نجريه من تغييرات وتعديلات في السياسات
            المطبقة وعليه قبول أيه تعديلات نراها لازمة.
          </li>
          <li>
            يجوز لنا تعديل أو تحديث هذه السياسة بدون إرسال إخطار إليك بذلك؛ لذا
            عليك مراجعة هذه الاتفاقية بشكل دوري.
          </li>
          <li>
            أنت توافق على الالتزام بكافة الشروط والبنود الإضافية التي ستتاح لك
            والتي تتعلق باستخدام أي من الخدمات المتاحة عبر موقع معاون، ويتم دمج
            هذه الشروط والبنود الإضافية إلى هذه الاتفاقية.
          </li>
        </ul>
        <h5>عاشرا: تحويل الحقوق والالتزامات</h5>
        <ul class="list-unstyled">
          <li>
            يحق لمعاون تحويل كافة حقوقه الواردة في هذه الاتفاقية للغير دون أي
            اعتراض من المستخدم، ويلتزم الجميع بتنفيذ كافة التزاماتهم الواردة في
            هذه الاتفاقية قِبل المحال إليه فور إخطارهم بالحوالة، ويحق للمحال
            إليه اتخاذ كافة الإجراءات القانونية قِبل المتقاعس عن تنفيذ التزاماته
            بموجب هذه الاتفاقية.
          </li>
          <li>
            لا يجوز لك التنازل عن التزاماتك وحقوقك بموجب هذه الاتفاقية، أو أن
            تعهد بإدارة حسابك بالموقع إلى طرف ثالث إلا بعد الحصول على موافقة
            خطية منا.
          </li>
        </ul>
        <h5>حادي عشر: إلغاء الاتفاق</h5>
        <ul class="list-unstyled">
          في حالة ثبوت قيامك بالإخلال بأي فقرة أو بند من بنود هذه الاتفاقية
          أعلاه، مع حفظ كافة حقوقنا في المطالبة بالتعويض عن الأضرار المترتبة على
          ذلك.

          <li>التنازل عن الحساب أو تأجيره من الباطن دون موافقتنا.</li>
          <li>
            تقاعس المستخدم عن الوفاء بأية التزامات منصوص عليها في هذه الاتفاقية
            دون أن يتخذ إجراءات جدية لإزالة هذا الإخلال، مع الاحتفاظ بحقنا في
            المطالبة بالتعويضات إن كان لها محل.
          </li>
          <li>عدم التمكن من توثيق أي معلومات قمت بتزويدنا بها.</li>
          <li>
            إذا قررنا في أي وقت أن نشاطاتك قد تتسبب لك أو لمستخدمين آخرين في
            نزاعات قانونية.
          </li>
          <li>توقف نشاط الموقع، أو توقف نشاط المسئولين عن إدارة الموقع.</li>
        </ul>
        <h5>الثاني عشر: القانون واجب التطبيق والمحاكم المختصة</h5>
        <ul class="list-unstyled">
          <li>
            يحكم هذه الاتفاقية القوانين المعمول بها والسارية المفعول داخل الدولة
            المحلية.
          </li>
          <li>
            يختص القضاء داخل الدولة المحلية – محاكم المملكة العربية السعودية -
            بأي نزاع قد ينشأ عن تفسير أو تنفيذ أحكام هذه الاتفاقية.
          </li>
          <li>
            في حال أصبح أي حكم من أحكام هذه الاتفاقية غير ساري أو غير قانوني أو
            غير قابل للتنفيذ، فان قانونية وقابلية تنفيذ الأحكام الأخرى لن تتأثر
            بأي طريقة كانت بذلك الحكم.
          </li>
        </ul>
        <h5>الثالث عشر: الإشعارات</h5>
        <ul class="list-unstyled">
          <li>
            أي إشعارات تود إرسالها لنا يجب أن يتم إرسالها عبر الخواص المحددة في
            الموقع، ولا يعتد بأي إشعارات يتم إرسالها خارج الموقع.
          </li>
          <li>
            أي إشعارات نود إرسالها إليك، تكون إما عن طريق إعلانها على الموقع أو
            عن طريق إرسالها إليك عبر رقم الجوال أو البريد الالكتروني الذي زودتنا
            بهم خلال عملية التسجيل، ويفترض علمك بالإشعار بمجرد الإعلان على
            الموقع، أو بمجرد مرور (24) ساعة من وقت إرسال الرسالة إليك.
          </li>
        </ul>
      </template>
    </content-component>
  </div>
</template>
<script>
import TermsAndPrivacy from "@/components/terms-and-privacy/TermsAndPrivacyTopComp.vue";
import ContentComponent from "@/components/terms-and-privacy/ContentComponent.vue";
export default {
  name: "PrivacyPolicy",
  components: {
    TermsAndPrivacy,
    ContentComponent,
  },
  data() {
    return {
      heading: "الشروط والأحكام",
      description: `نرحب بكم في موقع  "معاون"، فأهلا ومرحبا بكم.`,
    };
  },
};
</script>
<style scoped>
.content {
  border-radius: 12px;
  border: 1px solid #5264ae;
  background: #171b21;
  backdrop-filter: blur(22px);
  padding: 24px;
}
.content p {
  line-height: 2;
}
.content h5 {
  font-weight: bold;
  padding-right: 8px;
  margin-top: 32px;
  margin-bottom: 10px;
  border-right: 4px solid rgba(82, 100, 174, 1);
}
@media (max-width: 768px) {
  .container {
    width: 95%;
  }
  .content {
    padding: 15px;
  }
  .content h5 {
    line-height: normal;
  }
  .content h4 {
    font-size: 18px;
  }
}
@media (max-width: 556px) {
  .container {
    width: 95%;
  }
}
.quoted-text {
  quotes: "" " " "";
}
.quoted-text::before,
.quoted-text::after,
.quoted-text q::before,
.quoted-text q::after {
  color: #5264ae; /* Change this to your desired color */
}
.content ul > li {
  position: relative;
  margin: 8px 0;
  padding-right: 10px;
}
.content ul > li::before {
  padding-left: 10px;
  position: absolute;
  right: -5px;
  top: -14px;
  font-size: 2em;
  content: "\2022";
  color: #fff;
}
</style>
