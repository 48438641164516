<template>
  <div class="container"><h3 class="mb-5">آلية عمل منصة معـــاون!</h3></div>
  <div class="container pt-5 mt-5">
    <div class="step-item text-center position-relative">
      <div class="image-container">
        <img
          src="../../assets/imgs/about/step-1.svg"
          class="step-image w-100"
          alt="step-1"
          loading="lazy"
        />
      </div>
      <h4>سجل الدخول في المنصة</h4>
      <p>
        عبر خانة التسجيل اختر مربع صاحب مشروع سجل دخولك حتى يمكنك التواصل مع
        المستقلين والرجوع لسجل الطلبات، والاستفادة من كافة خدمات المنصة.
      </p>
    </div>
    <div class="step-item text-center position-relative">
      <div class="image-container">
        <img
          src="../../assets/imgs/about/step-2.svg"
          class="step-image w-100"
          alt="step-1"
          loading="lazy"
        />
      </div>
      <h4>ابحث عن معاون</h4>
      <p>
        بعد تسجيل الدخول اذهب إلى صفحة (المعاونون) وابحث عن المعاون المناسب،
        يمكنك فرز عملية البحث وتخصيصها وفقًا لاحتياجك من الخدمات.
      </p>
    </div>
    <div class="step-item text-center position-relative">
      <div class="image-container">
        <img
          src="../../assets/imgs/about/step-3.svg"
          class="step-image w-100"
          alt="step-1"
        />
      </div>
      <h4>قدم الطلب</h4>
      <p>
        بعد إيجاد المعاون المناسب قدم طلبًا بالضغط على مربع (تقديم) سيتم إرسال
        تنبيه في الحال إلى المعاون، ثم يتم التواصل معك في أقرب فرصة ممكنة.
      </p>
    </div>
    <div class="step-item text-center position-relative mb-0">
      <div class="image-container">
        <img
          src="../../assets/imgs/about/step-4.svg"
          class="step-image w-100"
          alt="step-1"
        />
      </div>
      <h4>بعد قبول الطلب</h4>
      <p>
        يدفع العميل قيمة الخدمة ولا يتم تسليمها للمستقل إلا بعد التراضي وتنفيذ
        الطلب بالشروط المتفق عليها.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.step-item {
  border-radius: 4px;
  background: rgba(21, 27, 40, 0.7);
  padding: 80px 20px 5px;
  margin-bottom: 100px;
}
.image-container {
  position: absolute;
  top: -90px;
  left: 50%;
  transform: translateX(-50%);
  width: 250px; /* Adjust if needed */
}
.step-image {
  width: 100%;
  height: auto;
  display: block;
}
</style>
