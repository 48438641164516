<template>
  <div class="app-layout">
    <v-layout class="position-relative">
      <app-nav v-if="!isDashboard"></app-nav>
      <v-main style="min-height: calc(100vh); padding-top: 0">
        <slot></slot>
      </v-main>
      <app-footer v-if="!isDashboard"></app-footer>
    </v-layout>
  </div>
</template>

<script>
import AppNav from "./AppNav.vue";
import AppFooter from "./AppFooter.vue";
export default {
  components: {
    AppNav,
    AppFooter,
  },
  computed: {
    isDashboard() {
      return this.$route.path.startsWith("/dashboard");
    },
  },
};
</script>

<style></style>
