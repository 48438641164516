<template>
  <AuthHero>
    <p>استعن بخدمات المستقلين المحترفين بثقة وأمان</p>
  </AuthHero>
  <div class="container pb-5 mb-5">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6">
        <form>
          <div>
            <label>البريد الإلكتروني</label>
            <v-text-field
              dir="rtl"
              type="email"
              density="compact"
            ></v-text-field>
          </div>
          <div>
            <label>كلمة المرور</label>
            <v-text-field
              dir="rtl"
              :prepend-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
              :type="visible ? 'text' : 'password'"
              density="compact"
              @click:prepend-inner="toggleVisibility"
            ></v-text-field>
          </div>
          <div class="d-flex justify-content-between mb-3">
            <div class="d-flex align-items-center gap-1">
              <input
                class="form-check-input custom-checkbox"
                type="checkbox"
                id="rememberMe"
                v-model="rememberMe"
              />
              <label class="form-check-label mb-0" for="rememberMe">
                تذكرني
              </label>
            </div>
            <router-link
              to="/forgot-password"
              class="text-decoration-none text-light pa-2"
              >هل نسيت كلمة المرور؟</router-link
            >
          </div>
          <div>
            <v-btn class="btn-custom light-btn login-btn">تسجيل الدخول</v-btn>
          </div>
          <div class="mt-2">
            <router-link to="/register" class="text-decoration-none text-light"
              >ليس لدي حساب !</router-link
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AuthHero from "@/components/auth/AuthHero.vue";

export default {
  data() {
    return {
      visible: false,
      rememberMe: false,
    };
  },
  methods: {
    toggleVisibility() {
      this.visible = !this.visible;
    },
  },
  components: {
    AuthHero,
  },
};
</script>

<style scoped>
form {
  border-radius: 12px;
  border: 1px solid #5264ae;
  background: #171b21;
  backdrop-filter: blur(22px);
  padding: 24px;
}
.custom-checkbox {
  width: 1.25em;
  height: 1.25em;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(45, 67, 131, 1);
  appearance: none;
  cursor: pointer;
}
.custom-checkbox:checked {
  background-color: rgba(45, 67, 131, 1);
  border-color: rgba(45, 67, 131, 1);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-label {
  color: #fff;
  cursor: pointer;
}
.form-check-input:focus {
  box-shadow: none !important;
  border-color: none !important;
}
@media (max-width: 992px) {
  .login-btn {
    width: 100%;
  }
}
</style>
